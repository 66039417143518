import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import * as firebaseService from './services/firebase';
import { logger } from './logging';

// fixing parcel livereload with firebase hosting
if ((module as any).hot) {
  (module as any).hot.accept(() => {
    setTimeout(() => {
      return null;
    }, 300);
  });
}

logger.debug('index.tsx calling firebaseService.initialize()');
// initialize the default app
const damApp = firebaseService.getDAMApp();
// initialize the canopus app
firebaseService.getCanopusApp();
// initialize the ifb app
firebaseService.getIFBApp();
// initialize the party service app
firebaseService.getPartyServiceApp();
// get firebase functions
const functions = getFunctions(damApp);

logger.info(`NODE_ENV: ${process.env.NODE_ENV}`);

// tell firebase to use the cloud functions emulator (for onCall) if env is development
if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'testing') {
  connectFunctionsEmulator(functions, 'dd-account-management.local', 5001);
}

if (window.location.hostname !== process.env.HOST) {
  logger.info(
    `window.location.hostname (${window.location.hostname}) is not equal to env.HOST (${process.env.HOST})`
  );
  if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'testing') {
    window.location.href = `http://${process.env.HOST}`;
  }

  window.location.href = `https://${process.env.HOST}`;
}

import 'bootstrap/dist/css/bootstrap.min.css';

import { App } from './App';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

root.render(<App />);
