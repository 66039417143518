import * as React from 'react';
import { labels as labelsObj } from './labelsModel';
import { createContext, useState, useEffect } from 'react';
import * as firebaseService from '../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Loader } from '../../components/Loader';
import { getAuth } from 'firebase/auth';

export const LabelsContext = createContext({
  labels: labelsObj,
  loadingLabels: false,
});

export const LabelsProvider = ({ children }) => {
  const damApp = firebaseService.getDAMApp();
  const damAuth = getAuth(damApp);
  const [labels, setLabels] = useState(labelsObj || ({} as any));
  const [loadingLabels, setLoadingLabels] = useState(false);
  const [user, userLoading] = useAuthState(damAuth as any);

  const loadLabels = async () => {
    setLoadingLabels(true);
    await labels.fetch();
    setLabels(labels);
    setLoadingLabels(false);
  };

  useEffect(() => {
    if (user) {
      loadLabels();
    }
  }, [user]);

  return (
    <LabelsContext.Provider value={{ labels, loadingLabels }}>
      {children}
    </LabelsContext.Provider>
  );
};
