import { get } from 'firebase/database';
import { getDAMApp } from '../../services/firebase';
import { ref } from '../../utils/firebase';

export class Enumerations {
  enumerations = {};

  constructor() {
    this.fetch = this.fetch.bind(this);
  }

  async fetch() {
    const damApp = getDAMApp();
    const enumRef = ref('enumerations');
    const res = await get(enumRef);
    this.enumerations = res.val();
  }

  getLabel(type: string, value: string): string {
    const typeObj = this.enumerations[type];
    if (!typeObj) {
      return value;
    }

    const valueObj = typeObj[value];
    if (!valueObj) {
      return value;
    }
    return valueObj.label;
  }
}

export const enumerations = new Enumerations();
