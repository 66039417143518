import * as React from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
const R = require('ramda');

import { BrowserRouter as Router } from 'react-router-dom';

import { NavBar } from './components/NavBar';
import { Routes } from './Routes';
import { AbilityContext } from './auth/Can';
import { ability } from './auth/ability';
import { EnumerationsProvider } from './pages/enumerations/EnumerationsContext';
import { LabelsProvider } from './pages/labels/LabelsContext';
import { CountriesProvider } from './pages/country-manager/CountriesContext';
import { FlagsProvider } from 'flagged';
import { Loader } from './components/Loader';
import { getDAMApp } from './services/firebase';
import { logger } from '../functions/src/logging';
import { getDatabase, ref } from 'firebase/database';

const styles = require('./app.css');

export const App = (props) => {
  const damApp = getDAMApp();
  const damDatabase = getDatabase(damApp);
  const featuresRef = ref(damDatabase, 'features');
  const [features, featuresLoading, featuresError] = useObjectVal(
    featuresRef as any
  );

  if (featuresLoading) {
    return <Loader loading={true} />;
  }

  // logger.info(`features: ${JSON.stringify(features)}`);

  const returnEnabled = (num, key, obj) => {
    if (obj[key].enabled === true) {
      return obj[key].enabled;
    }
  };

  const enabledFeatures = R.mapObjIndexed(returnEnabled, features);
  logger.debug(`enabledFeatures: ${JSON.stringify(enabledFeatures)}`);

  return (
    <FlagsProvider features={enabledFeatures}>
      <AbilityContext.Provider value={ability}>
        <EnumerationsProvider>
          <LabelsProvider>
            <CountriesProvider>
              <div className={styles.container}>
                <Router>
                  <NavBar />
                  <Routes />
                </Router>
              </div>
            </CountriesProvider>
          </LabelsProvider>
        </EnumerationsProvider>
      </AbilityContext.Provider>
    </FlagsProvider>
  );
};
