import * as React from 'react';
import { useList } from 'react-firebase-hooks/database';
import { ref } from '../../utils/firebase';
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { useContext, useState } from 'react';
import { Loader } from '../../components/Loader';
import { logger, serverLogger } from '../../logging';
import { AbilityContext, Can } from '../../auth/Can';
import { useAuthState } from 'react-firebase-hooks/auth';
import * as firebaseService from '../../services/firebase';
import { getAuth } from 'firebase/auth';
import { update } from 'firebase/database';
const R = require('ramda');

const pagesStyles = require('../pages.css');
const countryManagerStyles = require('./country-manager.css');
const iconStyles = require('../../components/icons.css');

const CountryRow = ({
  refKey,
  val,
  tdInstances,
  isTreasureDataAdmin,
  user,
}) => {
  const countryRef = ref(`countries/${refKey}`);
  const [treasureDataInstance, setTreasureDataInstance] = useState(
    val.treasure_data_instance || ''
  );
  const [selectedLabel, setSelectedLabel] = useState('');
  const [saving, setSaving] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = (ok?) => {
    if (ok === true) {
      logger.debug(`modal confirmed. save now.`);
    }
    setModal(!modal);
  };

  const saveCountry = async () => {
    try {
      setSaving(true);
      toggle(true);
      serverLogger(
        `[Country Mananger] User ${user.email} attempting to change setting for country ${refKey} to Treasure Data Instance: ${treasureDataInstance}`,
        'info'
      );
      await update(countryRef, {
        ...val,
        treasure_data_instance: treasureDataInstance,
      });
      setSaving(false);
    } catch (e) {
      setSaving(false);
      logger.error(e);
    }
  };

  const handleTdInstanceChange = (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    const label = e.nativeEvent.target[index].text;
    const value = e.target.value;
    setTreasureDataInstance(value);
    setSelectedLabel(label);
  };

  const treasureDataAccountOptions = R.map((snap: any) => {
    const account = snap.val();
    return (
      <option value={account.key} key={account.key}>
        {account.account_name}
      </option>
    );
  }, tdInstances);

  const tdInstanceSelectdisabled = !isTreasureDataAdmin;

  return (
    <React.Fragment>
      <tr>
        <td>{val.value}</td>
        <td>
          <Input
            name="label"
            placeholder="Label"
            value={val.label}
            disabled={true}
            readOnly={true}
          />
        </td>
        <td>
          <Input
            type="select"
            value={treasureDataInstance}
            onChange={handleTdInstanceChange}
            disabled={tdInstanceSelectdisabled}
          >
            <option value="" key="">
              Please select
            </option>
            {treasureDataAccountOptions}
          </Input>
        </td>
        <td>
          <Button
            onClick={(e) => toggle(true)}
            className={countryManagerStyles.button}
            disabled={saving}
          >
            {saving ? 'Saving' : 'Save'} Country Settings
          </Button>
          {saving && (
            <img
              src="/images/loading.gif"
              className={countryManagerStyles.loader}
            />
          )}
        </td>
      </tr>
      <Modal isOpen={modal} toggle={(e) => toggle(false)}>
        <ModalHeader toggle={(e) => toggle(false)}>
          Please Confirm Country Change
        </ModalHeader>
        <ModalBody>
          {`Please confirm that you would like to change the Treasure Data instance to ${
            treasureDataInstance === '' ? 'null' : selectedLabel
          } for this country (${val.label})?`}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => saveCountry()}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => toggle(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export const CountryManager = () => {
  const damApp = firebaseService.getDAMApp();
  const damAuth = getAuth(damApp);
  const [countries, countriesLoading, countriesError] = useList(
    ref('countries')
  );
  const [tdInstances, tdInstancesLoading, tdInstancesError] = useList(
    ref('accounts/treasure_data')
  );
  const [user, userLoading] = useAuthState(damAuth as any);

  const ability = useContext(AbilityContext);
  const isTreasureDataAdmin = ability.can('manage', 'treasure_data_admin');

  if (countriesLoading || tdInstancesLoading || userLoading) {
    return <Loader loading={true} />;
  }

  if (countriesError || tdInstancesError) {
    return (
      <Row>
        <Col sm={12}>
          There was an error loading countries or Treasure Data accounts data
        </Col>
      </Row>
    );
  }

  return (
    <div className={pagesStyles.container}>
      <Row className={pagesStyles.headerRow}>
        <Col sm={12} className={pagesStyles.noPadding}>
          <h3>Country Manager</h3>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Country Code</th>
                <th>Label</th>
                <th>Treasure Data Instance</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {countries &&
                tdInstances &&
                countries.map((c) => (
                  <CountryRow
                    refKey={c.key}
                    val={c.val()}
                    key={c.key}
                    tdInstances={tdInstances}
                    isTreasureDataAdmin={isTreasureDataAdmin}
                    user={user}
                  />
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};
