import * as React from 'react';
import { useEffect, useState } from 'react';
import MultiSelectDropdown from './MultiSelectDropdown';
import { TableSearch } from './TableSearch';
import * as firebaseService from '../services/firebase';
import { getDAMApp, getIFBApp } from '../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Loader } from './Loader';
import { Col, Row } from 'reactstrap';
import {
  equalTo,
  getDatabase,
  orderByChild,
  query,
  ref,
  get,
  startAt,
  endAt,
  update,
} from 'firebase/database';
import { getAuth } from 'firebase/auth';

interface filter {
  type: 'search' | 'toggle';
  options: any[];
  query: string;
  customHandler: boolean;
  submitable?: boolean;
  exact?: boolean;
  primary?: boolean;
  number?: boolean;
  labels: {
    all?: string;
    some?: string;
    placeholder?: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  onSelect?: Function;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  onDeselect?: Function;
}

interface props {
  path: string;
  db: string;
  filters: filter[];
  title: string;
  setLoading: any;
  rowsToExport: any[];
  customQuery?: { query: string; value: string | number | boolean };
  setFilteredValues: React.SetStateAction<any>;
}

interface cloudFilter {
  customQuery?: {
    query: string;
    value: string | number | boolean;
    type?: 'exact' | 'broad';
    options?: any[];
  };
  path: string;
  db: string;
  range: number;
  filters: {
    primary: boolean;
    query: string;
    type: 'exact' | 'broad';
    value?: string | number;
    options?: any[];
  }[];
}

async function filter({ path, db, range, filters, customQuery }: cloudFilter) {
  const dbApp = db === 'ifb' ? getIFBApp() : getDAMApp();
  const fbDB = getDatabase(dbApp);
  const dataRef = ref(fbDB, path);
  const [primaryFilter] = customQuery
    ? [customQuery]
    : filters.filter((filter) => filter.primary);
  if (!primaryFilter) {
    return [];
  }

  const isValueNumberOrBool =
    typeof primaryFilter.value === 'number' ||
    typeof primaryFilter.value === 'boolean';
  let dataQuery = undefined;
  if (primaryFilter.type === 'broad') {
    dataQuery = query(
      dataRef,
      orderByChild(primaryFilter.query),
      equalTo(primaryFilter.options[0] || '')
    );
  } else if (isValueNumberOrBool) {
    dataQuery = query(
      dataRef,
      orderByChild(primaryFilter.query),
      equalTo(primaryFilter.value)
    );
  } else {
    // Help Typescript infer that value is expected to be string
    const primaryValue = primaryFilter.value as string;
    dataQuery = query(
      dataRef,
      orderByChild(primaryFilter.query),
      startAt(primaryValue.toUpperCase()),
      endAt(`${primaryValue.toLowerCase()}\uf8ff`)
    );
  }

  const data = (await get(dataQuery)).val() || [];
  const filteredValues = Object.values(data).filter((val: any) => {
    let passing = true;

    filters.forEach((filter) => {
      switch (filter.type) {
        case 'broad':
          if (
            !filter.options
              ?.map((option) => String(option).toLowerCase())
              .includes(String(val[filter.query]).toLowerCase()) &&
            filter.options?.length
          ) {
            passing = false;
          }
          break;
        case 'exact':
          if (
            !String(val[filter.query])
              .toLowerCase()
              .startsWith(String(filter.value).toLowerCase())
          ) {
            passing = false;
          }
          break;
      }
    });

    // When custom query is target_id, check for exact match
    if (customQuery && customQuery.query === 'target_id') {
      const queryValue = customQuery.value as string;
      passing = val.target_id?.toLowerCase() === queryValue.toLowerCase();
    }

    return passing;
  });
  return filteredValues;
}

const DDTableFilterStyles = require('./DDTable.css');

export const DDTableFilters = ({
  filters,
  setFilteredValues,
  title,
  path,
  db,
  customQuery,
  setLoading,
}: props) => {
  const damApp = firebaseService.getDAMApp();
  const damDatabase = getDatabase(damApp);
  const auth = getAuth(damApp);
  const [user] = useAuthState(auth as any);

  const userPreferenceRef = ref(
    damDatabase,
    `user_preferences/${user.uid}/filters`
  );
  const [userPreferenceVal, userPreferencesLoading] = useObjectVal(
    userPreferenceRef as any
  );

  const userPreference = React.useMemo(
    () => userPreferenceVal || {},
    [userPreferenceVal]
  );

  const [activeFilters, setActiveFilters] = useState({
    toggle: {},
    search: {},
  });
  const [refresh, setRefresh] = useState(0);
  const [primary, setPrimary] = useState('');
  const [range, setRange] = useState(1000);

  const inputRangeRef = React.useRef(null);

  // logger.debug(`uid: ${uid}`)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has('q')) {
      const query = params.get('q').split('-');
      (async () => {
        const newRows = await filter({
          path,
          db,
          range,
          filters: [
            {
              query: query[0],
              value: query[2] === 'number' ? Number(query[1]) : query[1] || 0,
              type: 'exact',
              primary: true,
            },
          ],
        });
        setFilteredValues(newRows);
      })();
    }
  }, []);

  useEffect(() => {
    setActiveFilters((old) => {
      filters.forEach((filter) => {
        if (filter.type === 'toggle' && !old.toggle[filter.query]?.length) {
          old.toggle[filter.query] = [];
          return;
        }
      });
      return old;
    });
    const [firstFilter] = filters;
    setPrimary(firstFilter.query);
  }, []);

  async function filterValues() {
    const filters = [];
    setLoading(true);
    Object.entries(activeFilters.search).forEach(
      (
        searchQuery: [
          string,
          {
            input: string;
            select: string;
            submitable: boolean;
            customHandler: boolean;
            number: boolean;
            id: string;
          }
        ]
      ) => {
        if (searchQuery[1].customHandler) {
          return;
        }
        const searchValue = searchQuery[1].input;
        const filter = {
          value: searchValue,
          query: searchQuery[1].select,
          type: 'exact',
          primary: searchQuery[1].id === primary,
        };
        filters.push(filter);
      }
    );

    Object.entries(activeFilters.toggle).forEach((toggleQuery: any) => {
      if (toggleQuery[2]) {
        return;
      }
      const filter = {
        type: 'broad',
        query: toggleQuery[0],
        options: toggleQuery[1].map((val) => val[0]),
        primary: toggleQuery[0] === primary,
      };

      filters.push(filter);
    });

    const newRows = await filter({
      path: path,
      db: db,
      range,
      filters: filters,
      customQuery,
    });
    setFilteredValues(newRows);
    setLoading(false);
  }

  useEffect(() => {
    filterValues();
  }, [
    JSON.stringify(activeFilters.search),
    refresh,
    primary,
    JSON.stringify(customQuery),
  ]);

  useEffect(() => {
    if (!userPreference) return;
    setActiveFilters((old) => {
      filters.forEach((filter) => {
        if (filter.customHandler) {
          return;
        }
        if (
          filter.type === 'search' &&
          userPreference[filter.query] &&
          userPreference[filter.query]?.synced
        ) {
          const { input, select, submitable, customHandler } =
            userPreference[filter.query];
          if (
            input !== undefined &&
            select !== undefined &&
            submitable !== undefined
          ) {
            old.search[filter.query] = {
              input,
              select,
              submitable,
              customHandler,
            };
          }
        }
        if (
          filter.type === 'toggle' &&
          userPreference[filter.query] &&
          userPreference[filter.query]?.synced
        ) {
          old.toggle[filter.query] = [
            ...(userPreference[filter.query]?.values || []),
          ];
        }
      });
      return old;
    });
    setRefresh((refresh) => refresh + 1);
  }, [userPreference]);

  if (userPreferencesLoading) {
    return <Loader loading={true} />;
  }

  return (
    <>
      <Row>
        <Col>
          <h5>{title}</h5>
        </Col>
      </Row>

      <div className={DDTableFilterStyles.filterContainer}>
        {filters.map((filter, key) => {
          switch (filter.type) {
            case 'search':
              return (
                <Row
                  key={key}
                  className={DDTableFilterStyles.filterSearchContainer}
                >
                  <Col sm={{ size: 12 }}>
                    <TableSearch
                      defaultValues={{
                        select:
                          activeFilters.search[filter.query]?.select || '',
                        input: activeFilters.search[filter.query]?.input || '',
                      }}
                      options={filter.options}
                      submitable={filter.submitable}
                      onSelect={(id, queryName) => {
                        if (filter.customHandler) {
                          filter.onSelect(id);
                          return;
                        }

                        setActiveFilters((old) => {
                          old.search = {
                            [queryName]: {
                              input: id,
                              select: queryName,
                              submitable: true,
                              customHandler: filter.customHandler,
                              number: filter.number,
                              id: filter.query,
                            },
                          };
                          return old;
                        });
                        setRefresh((refresh) => refresh + 1);
                      }}
                      onSubmit={(input, select, submitable) => {
                        setRefresh((refresh) => refresh + 1);
                        if (filter.customHandler) {
                          filter.onSelect(input, select, submitable);
                        }
                        if (
                          userPreference[filter.query]?.synced &&
                          !filter.customHandler
                        ) {
                          update(userPreferenceRef, {
                            [filter.query]: {
                              ...userPreference[filter.query],
                              input,
                              select,
                              submitable,
                              customHandler: filter.customHandler,
                            },
                          });
                        }
                        setActiveFilters((old) => {
                          old.search = {
                            [filter.query]: {
                              input,
                              select,
                              submitable,
                              customHandler: filter.customHandler,
                              number: filter.number,
                              id: filter.query,
                            },
                          };
                          return old;
                        });
                      }}
                      onDeselect={(queryName) => {
                        setActiveFilters((old) => {
                          old.search[queryName] = {
                            input: ' ',
                            select: queryName,
                            submitable: true,
                            customHandler: filter.customHandler,
                            number: filter.number,
                            id: filter.query,
                          };
                          return old;
                        });
                        setRefresh((refresh) => refresh + 1);
                      }}
                      onChange={(input, select, submitable) => {
                        setRefresh((refresh) => refresh + 1);
                        if (filter.customHandler) {
                          filter.onSelect(input, select, submitable);
                          return;
                        }

                        if (
                          userPreference[filter.query]?.synced &&
                          !filter.customHandler
                        ) {
                          update(userPreferenceRef, {
                            [filter.query]: {
                              ...userPreference[filter.query],
                              input,
                              select,
                              submitable,
                            },
                          });
                        }
                        setActiveFilters((old) => {
                          old.search[filter.query] = {
                            input,
                            select,
                            submitable,
                            customHandler: filter.customHandler,
                            number: filter.number,
                            id: filter.query,
                          };
                          return old;
                        });
                      }}
                    />
                  </Col>
                </Row>
              );
            case 'toggle':
              return (
                <Row
                  key={key}
                  className={DDTableFilterStyles.filterToggleContainer}
                >
                  <Col sm={{ size: 8 }}>
                    <MultiSelectDropdown
                      defaultChecked={
                        userPreference[filter.query]?.synced
                          ? activeFilters.toggle[filter.query]
                          : []
                      }
                      single={filter.query === primary}
                      label={{
                        all: filter.labels.all || 'Loading all rows',
                        some: filter.labels.some || 'Loading filtered',
                      }}
                      values={filter.options}
                      handleSelect={(id, position) => {
                        if (filter.customHandler) {
                          filter.onSelect(id, position);
                          return;
                        }

                        setActiveFilters((old) => {
                          old.toggle[filter.query].push([
                            id,
                            position,
                            filter.customHandler,
                            filter.number,
                            filter.query,
                          ]);
                          if (
                            userPreference[filter.query]?.synced &&
                            !filter.customHandler
                          ) {
                            update(userPreferenceRef, {
                              [filter.query]: {
                                ...userPreference[filter.query],
                                values: old.toggle[filter.query],
                              },
                            });
                          }

                          return old;
                        });
                        setRefresh((refresh) => refresh + 1);
                      }}
                      handleDeselect={(id, position) => {
                        if (filter.customHandler) {
                          filter.onDeselect(id, position);
                          return;
                        }

                        setActiveFilters((old) => {
                          old.toggle[filter.query] = old.toggle[
                            filter.query
                          ].filter((value) => value[0] !== id);
                          if (
                            userPreference[filter.query]?.synced &&
                            !filter.customHandler
                          ) {
                            update(userPreferenceRef, {
                              [filter.query]: {
                                ...userPreference[filter.query],
                                values: old.toggle[filter.query],
                              },
                            });
                          }

                          return old;
                        });
                        setRefresh((refresh) => refresh + 1);
                      }}
                    />
                  </Col>
                </Row>
              );
          }
        })}
      </div>
    </>
  );
};

export const countries = async () => {
  const countryOrder = {
    US: 0,
    AU: 1,
    CA: 2,
    DE: 3,
    FR: 4,
    GB: 5,
    ES: 6,
    SE: 7,
    JP: 8,
    IT: 9,
  };

  const ifbApp = firebaseService.getIFBApp();
  const countriesDB = getDatabase(ifbApp);
  const countriesRef = ref(countriesDB, 'countries');
  const countriesQuery = query(
    countriesRef,
    orderByChild('has_dm_account'),
    equalTo(true)
  );

  const countriesList: any[] = (await get(countriesQuery)).val();
  if (!countriesList) return [];
  const countryLen = Object.values(countryOrder).length;
  const countryArr = new Array(countryLen);
  const sorted = Object.values(countriesList || {}).filter((country) => {
    if (countryOrder[country.id] !== undefined) {
      countryArr[countryOrder[country.id]] = country;
      return false;
    }
    return true;
  });
  return [...countryArr, ...sorted];
};
