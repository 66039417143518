import * as React from 'react';
import { useState, useEffect } from 'react';
import { Loader } from '../../components/Loader';
import { useObjectVal } from 'react-firebase-hooks/database';
import { fuzzyTextFilterFnExp } from '../accounts/AccountsContainer';
import { DefaultColumnFilter } from '../../components/table/DefaultFilter';
import { useFilters, useSortBy, useTable, usePagination } from 'react-table';
import { CommunityLeadersTable } from './CommunityLeadersTable';
import { Alert, Button, Col, Collapse, Input, Row } from 'reactstrap';
import moment from 'moment';
import { ColumnsSelector } from '../../components/table/ColumnsSelector';
import { getDAMApp } from '../../services/firebase';
import { logger } from '../../logging';
import { getPartyServiceRecord } from '../tags/Tags';
import { CrossIcon, TickIcon } from '../../components/Icons';
import { getAuth } from 'firebase/auth';
import { ref } from '../../utils/firebase';
import { update } from 'firebase/database';

const R = require('ramda');
const communityStyles = require('./community.css');
const dotdigitalStyles = require('../account/dotdigital/dotdigital.css');
const iconStyles = require('../../components/icons.css');

export const CommunityLeaders = () => {
  const damApp = getDAMApp();
  const damAuth = getAuth(damApp);
  const TABLE_NAME = 'communityLeaders';

  const communityLeadersRef = ref('community/leaders');
  const [communityLeadersList, loading, error] = useObjectVal(
    communityLeadersRef as any
  );
  const [values, setValues] = useState([]);

  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
  const uid = damAuth.currentUser.uid;
  const userColsRef = ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  // when communityLeadersList changes
  // and there are no values yet
  // put values into the correct shape + update state with mapped values
  useEffect(() => {
    if (values.length === 0 && communityLeadersList) {
      const communityLeaders = Object.keys(communityLeadersList).filter((k) => {
        return communityLeadersList[k];
      });

      setValues(
        communityLeaders.map((k) => {
          const value = communityLeadersList[k];
          return { ...value };
        })
      );
    }
  }, [communityLeadersList]); // when communityLeadersList changes

  // updates the party service data against the community record
  const updatePartyServiceData = async (
    rowIndex: number,
    value: any,
    isValid: boolean,
    name: string,
    canopusId: string
  ) => {
    const communityRef = ref(`community/leaders/${values[rowIndex].id}`);
    const community = {};
    const now = moment().unix();

    const isCanopusValid = canopusId && canopusId !== '' ? true : false;
    // we use this to check the process of canopus being added to party service record after initial ingestion
    const hasPartyIdAndNoCanopusId =
      isCanopusValid === false && isValid === true;

    community['party_id'] =
      (value && /^\d+$/.test(value) && parseInt(value, 10)) || null;
    community['modified'] = now;
    community['party_name'] = name;
    community['partyIdValid'] = isValid;
    community['canopusId'] = canopusId ? `${canopusId}` : null;
    community['canopusName'] = ''; // clearing this as we're not pulling canopus record - just setting canopus ID based on party record - so for name look at party Name.
    community['has_party_id_and_no_canopus_id'] = hasPartyIdAndNoCanopusId;

    try {
      await update(communityRef, community);
    } catch (e) {
      logger.error(
        '[CommunityLeaders] [updatePartyServiceData] - error updating community row: ',
        e
      );
    }

    setValues((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            ...community,
          };
        }
        return row;
      })
    );
    return;
  };

  const EditablePartyServiceIDCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updatePartyServiceData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    const [loading, setLoading] = React.useState(false);
    const partyIdValid = original.partyIdValid || false;
    const [error, setError] = React.useState('');

    const onChange = async (e) => {
      const partyId = e.target.value;
      const re = /^\d+$/g;
      if (!re.test(partyId) && partyId !== '') {
        return;
      }

      setLoading(true);
      setValue(partyId);
      setError('');
      await lookupPartyId(partyId);
      setLoading(false);
    };

    const initialCheck = async (val) => {
      logger.debug(`[initalCheck] for row: ${index}`);
      const party_id = val;
      const re = /^\d+$/g;
      if (!re.test(party_id) && party_id !== '') {
        return;
      }

      setLoading(true);
      setValue(party_id);
      await lookupPartyId(party_id);
      setLoading(false);
    };

    const lookupPartyId = async (party_id) => {
      // why are we doing this twice?
      await updatePartyServiceData(index, party_id, false, '', '');

      const partyServiceRecord = await getPartyServiceRecord(party_id);
      logger.debug(`partServiceRecord: ${JSON.stringify(partyServiceRecord)}`);

      await updatePartyServiceData(
        index,
        party_id,
        partyServiceRecord.party_name !== '',
        partyServiceRecord.party_name !== ''
          ? partyServiceRecord.party_name
          : '',
        partyServiceRecord.canopus_id
      );
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      logger.debug(
        `[CommunityLeaders][useEffect] called - EditablePartyServiceIDCell initialValue changed`
      );
      setValue(initialValue);
      if (
        typeof original.partyIdValid === 'undefined' &&
        typeof initialValue !== 'undefined' &&
        /^\d+$/.test(initialValue)
      ) {
        initialCheck(initialValue);
      }
    }, [initialValue]);

    return (
      <React.Fragment>
        {error !== '' ? (
          <Alert color="danger" className={communityStyles.validationError}>
            {error}
          </Alert>
        ) : null}
        <Row>
          <Col sm={10}>
            <Input type="text" value={value || ''} onChange={onChange} />
          </Col>
          <Col sm={2}>
            {loading && (
              <img
                src="/images/loading.gif"
                className={dotdigitalStyles.canopusLoader}
              />
            )}
            {!loading && partyIdValid && (
              <TickIcon fill="black" class={iconStyles.tick} />
            )}
            {!loading && !partyIdValid && (
              <CrossIcon fill="black" class={iconStyles.cross} />
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: 'Community Leader ID',
        accessor: 'id',
        id: 'id',
      },
      {
        Header: 'Community Leader Name',
        accessor: 'name',
        id: 'name',
        disableFilters: true,
        enableSortingRemoval: true,
      },
      {
        Header: 'Canopus ID',
        accessor: 'canopusId',
        id: 'canopusId',
      },
      {
        Header: 'Canopus Name',
        accessor: 'canopusName',
        id: 'canopusName',
      },
      {
        Header: 'Party Service ID',
        accessor: (row) => {
          if (row.party_id) {
            return row.party_id;
          }

          return '';
        },
        Cell: EditablePartyServiceIDCell,
        id: 'party_id',
      },
      {
        Header: 'Party Service Name',
        accessor: 'party_name',
        id: 'party_name',
      },
      {
        Header: 'Created',
        accessor: (row) => {
          if (row.created) {
            return moment
              .unix(parseInt(row.created))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'created',
      },
      {
        Header: 'Modified',
        accessor: (row) => {
          if (row.modified) {
            return moment
              .unix(parseInt(row.modified))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'modified',
      },
      {
        Header: 'Removed from Community',
        accessor: (row) => {
          if (row.removedFromCommunity === true) {
            return 'true';
          }

          return 'false';
        },
        id: 'removedFromCommunity',
      },
    ];

    return cols;
  }, []);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize values - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => values, [values]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const tableData = useTable(
    {
      columns,
      data: records,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
        filters: [
          {
            id: 'isDeletedFromDD',
            value: 'false',
          },
        ],
        pageSize: 20,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
      updatePartyServiceData,
    } as any,
    useFilters,
    useSortBy,
    usePagination
  ) as any;

  React.useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  if (loading || userColsLoading) {
    return <Loader loading={true} />;
  }

  if (error) {
    return <div>{`Error: ${error.message}`}</div>;
  }

  if (userColsError) {
    return <div>Error loading cols</div>;
  }

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);

  return (
    <div className={communityStyles.fullHeight}>
      <Row>
        <Col sm={{ size: 12 }}>
          <Button
            onClick={toggleColumnsSelect}
            className={communityStyles.columnsButton}
          >
            Columns
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Collapse isOpen={isColumnsSelectOpen}>
            <ColumnsSelector
              columns={columns}
              userCols={userCols}
              tableName={TABLE_NAME}
            />
          </Collapse>
        </Col>
      </Row>
      <Row className={communityStyles.fullHeight}>
        <Col sm={{ size: 12 }} className={communityStyles.fullHeight}>
          <CommunityLeadersTable {...tableData} />
        </Col>
      </Row>
    </div>
  );
};
