import * as React from 'react';
import { useList } from 'react-firebase-hooks/database';
import { useState } from 'react';
import { ref } from '../../utils/firebase';
import { arrayToOptions } from '../../utils/react';
import { Row, Col, Button, Table, Input, FormGroup, Alert } from 'reactstrap';
import { PlusIcon } from '../../components/Icons';
import { logger } from '../../logging';
import { Loader } from '../../components/Loader';
import { get, remove, update } from 'firebase/database';

const pagesStyles = require('../pages.css');
const featuresStyles = require('./features.css');
const iconStyles = require('../../components/icons.css');

const FeatureRow = ({ refKey, val }) => {
  const [name, setName] = useState(val.name);
  const [description, setDescription] = useState(val.description);
  const [enabled, setEnabled] = useState(val.enabled);
  const featureRef = ref(`features/${refKey}`);

  const updateFeature = async () => {
    try {
      await update(featureRef, { ...val, name, description, enabled });
    } catch (e) {
      logger.error(e);
    }
  };

  const deleteFeature = async () => {
    try {
      await remove(featureRef);
    } catch (e) {
      logger.error(e);
    }
  };

  const handleCheckChanged = (e) => {
    // logger.debug(`e: ${JSON.stringify(e.target.checked)}`);
    setEnabled(e.target.checked);
  };

  return (
    <tr>
      <td>{val.featureKey}</td>
      <td>
        <Input
          name="name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </td>
      <td>
        <Input
          name="description"
          type="textarea"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </td>
      <td className={featuresStyles.center}>
        <Input
          name="enabled"
          checked={enabled}
          type="checkbox"
          onChange={handleCheckChanged}
        />
      </td>
      <td>
        <Button
          onClick={(e) => updateFeature()}
          className={featuresStyles.button}
        >
          Update
        </Button>
        <Button
          onClick={(e) => deleteFeature()}
          className={featuresStyles.button}
        >
          Delete
        </Button>
      </td>
    </tr>
  );
};

const CreateFeatureRow = ({ setValidationError }) => {
  const [featureKey, setFeatureKey] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const reset = () => {
    setFeatureKey('');
    setName('');
    setDescription('');
    setEnabled(false);
    setDisabled(true);
  };

  const createFeature = async () => {
    try {
      const featureRef = ref(`features/${featureKey}`);

      // check for existing key first
      const existingKey = await get(featureRef);
      if (existingKey.exists()) {
        logger.info(`key already exists: features/${featureKey}`);
        setValidationError(
          'Duplicate keys are not allowed, please use a different key'
        );
        reset();
        return;
      }

      const val = { featureKey, name, description, enabled };
      await update(featureRef, val);
      reset();
    } catch (e) {
      logger.error(e);
      reset();
    }
  };

  const handleNameChanged = (e) => {
    const val = e.target.value;
    setName(val);
    setDisabled(true);
    setValidationError('');
    if (val !== '' && featureKey !== '' && description !== '') {
      setDisabled(false);
    }
  };

  const handleKeyChanged = (e) => {
    const val = e.target.value;
    setFeatureKey(val);
    setDisabled(true);
    setValidationError('');
    if (val !== '' && name !== '' && description !== '') {
      setDisabled(false);
    }
  };

  const handleDescriptionChanged = (e) => {
    const val = e.target.value;
    setDescription(val);
    setDisabled(true);
    setValidationError('');
    if (val !== '' && featureKey !== '' && name !== '') {
      setDisabled(false);
    }
  };

  return (
    <tr>
      <td>
        <Input
          name="featureKey"
          placeholder="featureKey"
          value={featureKey}
          onChange={handleKeyChanged}
        />
      </td>
      <td>
        <Input
          name="name"
          placeholder="Name"
          value={name}
          onChange={handleNameChanged}
        />
      </td>
      <td>
        <Input
          name="description"
          type="textarea"
          placeholder="Description"
          value={description}
          onChange={handleDescriptionChanged}
        />
      </td>
      <td></td>
      <td>
        <Button onClick={(e) => createFeature()} disabled={disabled}>
          <PlusIcon fill="white" class={iconStyles.addButton} />
        </Button>
      </td>
    </tr>
  );
};

export const Features = () => {
  const [featureValues, featureLoading, featureError] = useList(
    ref('features')
  );
  const [validationError, setValidationError] = useState('');

  if (featureLoading) {
    return <Loader loading={true} />;
  }

  if (featureError) {
    return <React.Fragment>Error</React.Fragment>;
  }

  return (
    <div className={pagesStyles.container}>
      <Row className={pagesStyles.headerRow}>
        <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
          <h3>Features</h3>
        </Col>
      </Row>
      {validationError && validationError !== '' && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Alert color="danger">{validationError}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={{ size: 12 }}>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Key</th>
                <th>Name</th>
                <th>Description</th>
                <th>Enabled</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <CreateFeatureRow setValidationError={setValidationError} />
              {featureValues &&
                featureValues.map((c) => (
                  <FeatureRow refKey={c.key} val={c.val()} key={c.key} />
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};
