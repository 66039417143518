import * as React from 'react';
import { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { getDAMApp } from '../services/firebase';
import {
  equalTo,
  getDatabase,
  orderByChild,
  query,
  ref,
  get,
  startAt,
  endAt,
} from 'firebase/database';

export const DDAccountSelector = ({ accountId, setAccountId, disabled }) => {
  const [options, setOptions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const damApp = getDAMApp();
  const damDatabase = getDatabase(damApp);

  React.useEffect(() => {
    let isMounted = true;
    if (accountId !== null) {
      setIsLoading(true);
      const accountsRef = ref(damDatabase, 'accounts/dotdigital');
      const accountsQuery = query(
        accountsRef,
        orderByChild('ddAccountId'),
        equalTo(`${accountId}`)
      );
      get(accountsQuery).then((snap) => {
        const accounts = [];
        snap.forEach((s) => {
          accounts.push(s.val());
        });

        if (isMounted) {
          setSelectedAccount(accounts);
          setIsLoading(false);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [accountId]);

  const handleSearch = async (queryStr: string) => {
    setIsLoading(true);
    setSelectedAccount(null);

    const accounts = [];
    const accountsRef = ref(damDatabase, 'accounts/dotdigital');
    const accountsQuery = query(
      accountsRef,
      orderByChild('ddAccountName'),
      startAt(queryStr.toUpperCase()),
      endAt(`${queryStr.toLowerCase()}\uf8ff`)
    );

    const accountsSnap = await get(accountsQuery);
    accountsSnap.forEach((snap) => {
      const val = snap.val();

      if (val.active === true) {
        // only active accounts
        accounts.push({
          ddAccountName: val.ddAccountName,
          ddAccountId: val.ddAccountId,
          territoryOperatingCountry: val.territoryOperatingCountry,
          ddAccountType: val.ddAccountType,
        });
      }
    });

    // case-insensitive string sort
    accounts.sort((a, b) =>
      a.ddAccountName.localeCompare(b.ddAccountName, undefined, {
        sensitivity: 'base',
      })
    );

    setOptions(accounts);
    setIsLoading(false);
  };

  const handleChange = (e) => {
    if (e && e[0] && e[0].ddAccountId) {
      setSelectedAccount(e);
      setAccountId(e[0].ddAccountId);
    } else {
      setAccountId(null);
    }
  };

  return (
    <React.Fragment>
      <AsyncTypeahead
        isLoading={isLoading}
        id="dd-account-selector"
        useCache={false}
        labelKey={(account: any) =>
          `${
            account.ddAccountName
          } [${account.territoryOperatingCountry.toUpperCase()}] [${
            account.ddAccountType
          }]`
        }
        minLength={1}
        onSearch={handleSearch}
        onChange={handleChange}
        placeholder={'Find DD Account'}
        selected={selectedAccount || []}
        options={options}
        disabled={disabled}
      />
    </React.Fragment>
  );
};
