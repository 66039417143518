import * as React from 'react';
import { useState, useEffect } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Row, Col, Button, Collapse } from 'reactstrap';
import { AccountsTable } from '../AccountsTable';
import { Redirect } from 'react-router-dom';
import { Can } from '../../../auth/Can';
import { ExportToExcel } from '../ExportToExcel';
import { DefaultColumnFilter } from '../../../components/table/DefaultFilter';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { Loader } from '../../../components/Loader';
import { fuzzyTextFilterFnExp } from '../AccountsContainer';
import { logger } from '../../../logging';
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from 'moment';
import { ColumnsSelector } from '../../../components/table/ColumnsSelector';
import * as firebaseService from '../../../services/firebase';
import * as R from 'ramda';
import { getAuth } from 'firebase/auth';
import { ref } from '../../../utils/firebase';

const pagesStyles = require('../../pages.css');
const accountsStyles = require('../accounts.css');

export interface TreasureDataAccount {
  account_name: string;
  account_id: string;
  active: boolean;
  created_by: string;
  created_at: number;
  updated_by?: string;
  updated_at?: number;
}

interface Accounts {
  accounts: TreasureDataAccount[];
}

export const Accounts = ({ showActive, setShowActive }) => {
  const TABLE_NAME = 'treasureDataAccounts';
  const damApp = firebaseService.getDAMApp();
  const damAuth = getAuth(damApp);
  // get accounts from firebase hooks here
  const accountsRef = ref('accounts/treasure_data');

  const [createAccount, setCreateAccount] = useState(false);
  //const [showInactiveAccounts, setShowInactiveAccounts] = useState(false);

  const [accountsList, loading, error] = useObjectVal(accountsRef as any);
  const [values, setValues] = useState([]);

  const [user] = useAuthState(damAuth as any);

  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
  const uid = damAuth.currentUser.uid;
  const userColsRef = ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  // when accountsList changes
  // and there are no values yet
  // put values into the correct shape + update state with mapped values
  useEffect(() => {
    if (values.length === 0 && accountsList) {
      const accounts = Object.keys(accountsList).filter((k) => {
        return accountsList[k].active === showActive;
      });

      setValues(
        accounts.map((k) => {
          const value = accountsList[k];
          return { ...value, key: k };
        })
      );
    }
  }, [accountsList, showActive]); // when accountsList

  const toggleShowActive = () => {
    logger.debug(`[toggleShowActive] showActive: ${showActive}`);
    const show = !showActive;
    setShowActive(show);
    setValues([]);
  };

  const newAccount = () => {
    setCreateAccount(true);
  };

  const updateTableData = (rowIndex, columnId, value) => {
    setValues((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'DAM key',
        accessor: 'key',
        id: 'key',
      },
      {
        Header: 'Account Name',
        accessor: 'account_name',
        id: 'account_name',
      },
      {
        Header: 'Account ID',
        accessor: 'account_id',
        id: 'account_id',
      },
      {
        Header: 'Created',
        accessor: (row) => moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        id: 'created_at',
      },

      {
        Header: 'Modified',
        accessor: (row) => moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        id: 'modified_at',
      },
    ],
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize values - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => values, [values]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const tableData = useTable(
    {
      columns,
      data: records,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'account_name',
            desc: false,
          },
        ],
        pageSize: 20,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
      updateTableData,
    } as any,
    useFilters,
    useSortBy,
    usePagination
  ) as any;

  React.useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  if (createAccount === true) {
    return (
      <React.Fragment>
        <Redirect to="/accounts/new/treasure_data" />
      </React.Fragment>
    );
  }

  if (loading || userColsLoading) {
    return <Loader loading={true} />;
  }

  if (error) {
    return <div>{`Error: ${error.message}`}</div>;
  }

  if (userColsError) {
    return <div>Error loading cols</div>;
  }

  // depends on treasure data account name being unique
  const accountsFiltered = () => {
    const rows = tableData.rows.map((r) => r.values);

    return records.filter((v: any) => {
      const found = rows.find((r) => {
        return r.account_name === v.account_name;
      });

      if (found) {
        return true;
      }

      return false;
    });
  };

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);

  return (
    <div className={pagesStyles.fullHeight}>
      {values && (
        <div className={`${pagesStyles.noPadding} ${pagesStyles.fullHeight}`}>
          <Row className={pagesStyles.headerRow}>
            <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
              <Button
                onClick={(e) => toggleShowActive()}
                className={accountsStyles.button}
              >
                Show {showActive ? 'Inactive' : 'Active'} Accounts
              </Button>
              <Can I="create" an="account">
                <Button
                  onClick={(e) => newAccount()}
                  className={accountsStyles.button}
                >
                  Create New Account
                </Button>
              </Can>
              <Can I="export" an="accounts">
                <ExportToExcel
                  csvData={accountsFiltered()}
                  fileName={'accounts-treasure-data'}
                  user={user}
                />
              </Can>
              <Button
                onClick={toggleColumnsSelect}
                className={accountsStyles.button}
              >
                Columns
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Collapse isOpen={isColumnsSelectOpen}>
                <ColumnsSelector
                  columns={columns}
                  userCols={userCols}
                  tableName={TABLE_NAME}
                />
              </Collapse>
            </Col>
          </Row>
          <div className={pagesStyles.fullHeight}>
            <Row className={pagesStyles.fullHeight}>
              <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
                <AccountsTable {...tableData} system="treasure_data" />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};
