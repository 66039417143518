import * as React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Button } from 'reactstrap';
import { logger, serverLogger } from '../logging';
import {
  getDAMApp,
  getCanopusApp,
  getIFBApp,
  getPartyServiceApp,
} from '../services/firebase';
import { getAuth, signOut } from 'firebase/auth';

export const LogoutButton = () => {
  const damApp = getDAMApp();
  const ifbApp = getIFBApp();
  const canopusApp = getCanopusApp();
  const partyServiceApp = getPartyServiceApp();
  const damAuth = getAuth(damApp);
  const canopusAuth = getAuth(canopusApp);
  const ifbAuth = getAuth(ifbApp);
  const partyServieAuth = getAuth(partyServiceApp);

  const [user] = useAuthState(damAuth as any);

  const logout = () => {
    if (user) {
      const userEmail = user['email'];
      // logger.info('reidrecting to', from.pathname);
      try {
        serverLogger(`User logged out: ${userEmail}`, 'info');
      } catch (e) {
        logger.error('error calling serverlogger');
      }
    }

    signOut(damAuth);
    signOut(canopusAuth);
    signOut(ifbAuth);
    signOut(partyServieAuth);
  };

  if (user) {
    return <Button onClick={logout}>Log Out ({user.email})</Button>;
  }

  return <div></div>;
};
