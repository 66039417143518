import { Ability, AbilityBuilder } from '@casl/ability';
import { getDAMApp } from '../services/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, off, onValue, ref } from 'firebase/database';

export const ability = new Ability();

// used to configure CASL abilities on user login
const updateAbility = (ability, claims) => {
  const { can, rules } = new AbilityBuilder(Ability);

  if (!claims.isDAMUser && !claims.damPowerUser) {
    ability.update([]); // take all permissions away
    return;
  }

  if (claims.damPowerUser === true) {
    can('manage', 'all');
  }

  if (claims.damAccountsView === true) {
    can('read', 'account');
  }

  if (claims.ddLabelsManager === true) {
    can('manage', 'label');
  }

  if (claims.ddAccountsCreateAccount === true) {
    can('create', 'account');
  }

  if (claims.ddAccountsDeleteAccount === true) {
    can('delete', 'account');
  }

  if (claims.ddAccountsExportAccounts === true) {
    can('export', 'accounts');
  }

  if (claims.ddAccountsCreateCredential === true) {
    can('create', 'credential');
  }

  if (claims.ddAccountsDeleteCredential === true) {
    can('delete', 'credential');
  }

  if (claims.ddAccountsViewCredentials === true) {
    can('read', 'credential');
  }

  if (claims.ddAccountsShareCredential === true) {
    can('share', 'credential');
  }

  if (claims.ddCampaignsManager === true) {
    can('manage', 'campaign');
  }

  if (claims.ddUsersManager === true) {
    can('manage', 'user');
  }

  if (claims.ddCanopusManager === true) {
    can('manage', 'canopus');
  }

  if (claims.ddTagsManager === true) {
    can('manage', 'tag');
  }

  if (claims.ddCustomFieldsManager === true) {
    can('manage', 'custom_fields');
  }

  if (claims.ddCommunitiesManager === true) {
    can('manage', 'community');
  }

  if (claims.ddWorkflowManager === true) {
    can('manage', 'workflows');
  }

  if (claims.damWorkflowPrivacyManager === true) {
    can('manage', 'privacy_workflows');
    can('manage', 'workflows');
  }

  if (claims.damWorkflowAdmin === true) {
    can('manage', 'workflows_admin');
    can('manage', 'privacy_workflows');
    can('manage', 'workflows');
  }

  if (claims.ddOptinManager === true) {
    can('manage', 'optins');
  }

  if (claims.ddD2cArtistMappingManager === true) {
    can('manage', 'artist_mapping');
    can('manage', 'd2c_artist_mapping');
  }

  if (claims.damArtistAdmin === true) {
    can('manage', 'artist_mapping');
    can('manage', 'd2c_artist_mapping');
    can('manage', 'artist_admin');
  }

  if (claims.damAttentiveAdmin === true) {
    can('manage', 'attentive_admin');
  }

  if (claims.damTreasureDataAdmin === true) {
    can('manage', 'treasure_data_admin');
    can('manage', 'country');
  }

  if (claims.damPartyEngagementView === true) {
    can('read', 'party_engagement_status');
  }

  if (claims.damPartyEngagementAdmin === true) {
    can('manage', 'party_engagement_status');
    can('read', 'party_engagement_status');
  }

  if (claims.damDataAcquisitionAdmin === true) {
    can('manage', 'data_acquisition');
  }

  if (claims.damCountryAdmin === true) {
    can('manage', 'country');
  }

  if (claims.damFeaturesAdmin === true) {
    can('manage', 'features');
  }

  if (claims.damRolesAdmin === true) {
    can('manage', 'roles');
  }

  if (claims.damEnumerationsAdmin === true) {
    can('manage', 'enumerations');
  }

  if (claims.damCommunityAccountAdmin === true) {
    can('manage', 'community_admin');
  }

  if (claims.damBulkUpdateAddressbooks === true) {
    can('bulkUpdate', 'addressbooks');
  }

  if (claims.damLPR === true) {
    can('manage', 'lpr');
  }

  if (claims.export === true) {
    can('manage', 'export');
  }

  if (claims.damDDInsights === true) {
    can('manage', 'dd_insights');
  }

  if (claims.damAddressbookCountryAdmin === true) {
    can('manage', 'addressbook_country');
  }

  ability.update(rules);
};

// from https://firebase.google.com/docs/auth/admin/custom-claims#best_practices_for_custom_claims
// listen to auth state change - if admins change another users' claims this forces a refresh of the logged in users'
// claims + updates their abilities appropriately. Uses a user_metadata ref to trigger this.
let callback = null;
let metadataRef = null;

const damApp = getDAMApp();
const damDatabase = getDatabase(damApp);
const damAuth = getAuth(damApp);
onAuthStateChanged(damAuth, (user) => {
  // Remove previous listener.
  if (callback) {
    off(metadataRef, 'value', callback);
  }

  // On user login add new listener.
  if (user) {
    // Check if refresh is required.
    metadataRef = ref(
      damDatabase,
      'user_metadata/' + user.uid + '/refreshTime'
    );
    callback = async (snapshot) => {
      // Force refresh to pick up the latest custom claims changes.
      // Note this is always triggered on first call. Further optimization could be
      // added to avoid the initial trigger when the token is issued and already contains
      // the latest claims.

      const result = await user.getIdTokenResult(true);
      updateAbility(ability, result.claims);
    };
    // Subscribe new listener to changes on that node.
    onValue(metadataRef, callback);
  }
});
