import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Row, Col, Button, Collapse } from 'reactstrap';
import { AccountsTable } from '../AccountsTable';
import { SelectColumnFilter } from '../../../components/table/SelectColumnfilter';
import { Redirect } from 'react-router-dom';
import { Can } from '../../../auth/Can';
import { ExportToExcel } from '../ExportToExcel';
import { DefaultColumnFilter } from '../../../components/table/DefaultFilter';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import { Loader } from '../../../components/Loader';
import { Enumerations } from '../../enumerations/enumerationsModel';
import { Credential, fuzzyTextFilterFnExp } from '../AccountsContainer';
import { logger } from '../../../logging';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getDAMApp } from '../../../services/firebase';
import { Countries } from '../../country-manager/countriesModel';
import { ColumnsSelector } from '../../../components/table/ColumnsSelector';
import { getAuth } from 'firebase/auth';
import { ref } from '../../../utils/firebase';

const R = require('ramda');
const pagesStyles = require('../../pages.css');
const accountsStyles = require('../accounts.css');

export interface SlicktextAccount {
  id: number;
  name: string;
  country: string;
  language: string;
  timezone: string;
  credentials?: Credential[];
  createdBy: string;
  created: string;
  lastModifiedBy?: string;
  modified?: string;
  stAccountId?: string;
}

interface Accounts {
  accounts: SlicktextAccount[];
}

const getEnumLabels = (
  values,
  enumerations: Enumerations,
  countriesModel: Countries
) => {
  const enumMappings = {
    country: 'operatingCountries',
  };

  const enumsToLookup = ['country'];
  const labels = { ...values };
  try {
    enumsToLookup.forEach((type) => {
      if (type === 'country') {
        labels[type] = countriesModel.getName(values[type]);
      } else {
        labels[type] = enumerations.getLabel(enumMappings[type], values[type]);
      }
    });
  } catch (e) {
    logger.error(e, ' name: ', values.name, values, enumerations.enumerations);
  }

  return labels;
};

export const Accounts = ({
  enumerations,
  showActive,
  setShowActive,
  countriesModel,
}) => {
  const damApp = getDAMApp();
  const damAuth = getAuth(damApp);
  // get accounts from firebase hooks here
  const accountsRef = ref('accounts/slicktext');
  const TABLE_NAME = 'slicktextAccounts';
  const uid = damAuth.currentUser.uid;
  const userColsRef = ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  const [createAccount, setCreateAccount] = useState(false);
  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);

  const [accountsList, loading, error] = useObjectVal(accountsRef as any);
  const [values, setValues] = useState([]);

  const [user] = useAuthState(damAuth as any);

  // when accountsList changes or enumerations changes
  // and there are no values yet
  // put values into the correct shape + lookup enumeration labels + update state with mapped values
  useEffect(() => {
    if (values.length === 0 && accountsList && enumerations && countriesModel) {
      const accounts = Object.keys(accountsList).filter((k) => {
        return accountsList[k].active === showActive;
      });

      setValues(
        accounts.map((k) => {
          const value = accountsList[k];
          const labels = getEnumLabels(value, enumerations, countriesModel);
          return { ...labels, key: k };
        })
      );
    }
  }, [accountsList, enumerations, showActive, countriesModel]); // when accountsList or enumerations changes

  const toggleShowActive = () => {
    logger.debug(`[toggleShowActive] showActive: ${showActive}`);
    const show = !showActive;
    setShowActive(show);
    setValues([]);
  };

  const newAccount = () => {
    setCreateAccount(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'DAM key',
        accessor: 'id',
        id: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
      },
      {
        Header: 'Account ID',
        accessor: 'stAccountId',
        id: 'stAccountId',
      },
      {
        Header: 'Territory (Operating Country)',
        accessor: 'country',
        id: 'country',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Data Acquisition Processing Method',
        accessor: 'data_acquisition_processing_method',
        Filter: SelectColumnFilter,
        filter: 'equals',
        id: 'data_acquisition_processing_method',
      },
      {
        Header: 'Data Acquisition Selection',
        accessor: 'data_acquisition_selection',
        Filter: SelectColumnFilter,
        filter: 'equals',
        id: 'data_acquisition_selection',
      },
      {
        Header: 'Language',
        accessor: 'language',
        id: 'language',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
    ],
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize values - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => values, [values]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const tableData = useTable(
    {
      columns,
      data: records,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
        pageSize: 20,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;
  React.useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  if (createAccount === true) {
    return (
      <React.Fragment>
        <Redirect to="/accounts/new/slicktext" />
      </React.Fragment>
    );
  }

  if (loading || userColsLoading) {
    return <Loader loading={true} />;
  }

  if (error) {
    return <div>{`Error: ${error.message}`}</div>;
  }

  // depends on slicktext account id being unique
  const accountsFiltered = () => {
    const rows = tableData.rows.map((r) => r.values);

    return records.filter((v: any) => {
      const found = rows.find((r) => {
        return r.id === v.id;
      });

      if (found) {
        return true;
      }

      return false;
    });
  };

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);

  return (
    <div className={pagesStyles.fullHeight}>
      {values && (
        <div className={`${pagesStyles.noPadding} ${pagesStyles.fullHeight}`}>
          <Row className={pagesStyles.headerRow}>
            <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
              <Button
                onClick={(e) => toggleShowActive()}
                className={accountsStyles.button}
              >
                Show {showActive ? 'Inactive' : 'Active'} Accounts
              </Button>
              <Can I="create" an="account">
                <Button
                  onClick={(e) => newAccount()}
                  className={accountsStyles.button}
                >
                  Create New Account
                </Button>
                <Button
                  onClick={toggleColumnsSelect}
                  className={accountsStyles.button}
                >
                  Columns
                </Button>
              </Can>
              <Can I="export" an="accounts">
                <ExportToExcel
                  csvData={accountsFiltered()}
                  fileName={'accounts-slicktext'}
                  user={user}
                />
              </Can>
            </Col>
          </Row>
          <Col sm={12}>
            <Collapse isOpen={isColumnsSelectOpen}>
              <ColumnsSelector
                columns={columns}
                userCols={userCols}
                tableName={TABLE_NAME}
              />
            </Collapse>
          </Col>
          <div className={pagesStyles.fullHeight}>
            <Row className={pagesStyles.fullHeight}>
              <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
                <AccountsTable {...tableData} system="slicktext" />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};
