import * as React from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Alert,
} from 'reactstrap';
import { Field } from '@availity/form';
import { SelectField } from '@availity/select';
import '@availity/yup';
import { ref } from '../../../utils/firebase';
import { Loader } from '../../../components/Loader';
import { Can } from '../../../auth/Can';
import { logger } from '../../../logging';
import { useFormikContext } from 'formik';
import { equalTo, orderByChild, query, get } from 'firebase/database';

const accountStyles = require('../account.css');

// account name should be unique - maybe this should be onsubmit tho (as this is reused for view/edit)
// we should debounce this/throttle it
export const validateTDAccountName = async (
  account_name,
  id,
  setFieldError,
  setHasFormErrors
) => {
  logger.debug(
    `[TreasureDataFields] validateTDAccountName - account_name: ${account_name}, id: ${id}, typeof id: ${typeof id}`
  );
  const errorMessage = 'An account name must be provided and unique';

  const value = account_name ? account_name.trim() : '';

  if (!value || value === '') {
    logger.debug(
      '[TreasureDataFields] validateTDAccountName - there is no value, so we dont lookup. Returning as this is the cause of a setstate error'
    );
    setFieldError('name', errorMessage);
    return false; // there's no value so it is invalid
  }

  const accountsRef = ref('accounts/treasure_data');
  const accountsQuery = query(
    accountsRef,
    orderByChild('account_name'),
    equalTo(value)
  );
  const accountsSnap = await get(accountsQuery);

  const accounts = [];
  accountsSnap.forEach((v) => {
    accounts.push({ ...v.val(), id: v.key });
  });

  // so obviously there's an account with this name (if we're editing)
  if (accounts.length > 0) {
    const account = accounts[0];
    // if there is an account with this name but a different uid, then we have a validation error
    if (account.id !== id) {
      logger.debug(
        `[TreasureDataFields] validateTDAccountName - account.id (${account.id}) and id (${id}) are not the same`
      );
      setFieldError('account_name', errorMessage);
      return false;
    }
  }

  return true;
};

// account id should be unique
export const validateAccountId = async (rawValue, key, setFieldError) => {
  const errorMessage = 'An account ID must be provided and unique';

  const value = rawValue ? rawValue.trim() : '';

  if (!value || value === '') {
    logger.debug(
      '[TreasureDataFields] [validateAccountId] there is no value, so we dont lookup. Returning as this is the cause of a setstate error'
    );
    setFieldError('account_id', errorMessage);
    return false; // there's no value so it is invalid
  }

  const accountsRef = ref('accounts/treasure_data');
  const accountsQuery = query(
    accountsRef,
    orderByChild('account_id'),
    equalTo(value)
  );
  const accountsSnap = await get(accountsQuery);

  const accounts = [];
  accountsSnap.forEach((v) => {
    accounts.push({ ...v.val(), key: v.key });
  });

  // so obviously there's an account with this name (if we're editing)
  if (accounts.length > 0) {
    const account = accounts[0];
    // if there is an account with this name but a different uid, then we have a validation error
    if (account.key !== key) {
      logger.debug(
        `[TreasureDataFields] [validateAccountId] account.key (${account.key}) and key (${key}) are not the same`
      );
      setFieldError('account_id', errorMessage);
      return false;
    }
  }

  return true;
};

export const TreasureDataFields = ({
  model,
  setCanSave,
  system,
  account_id,
  errors,
}) => {
  const formik = useFormikContext();
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasCreds, setHasCreds] = useState(null);
  const [enumerations, enumerationsLoading] = useObjectVal(
    ref('enumerations') as any
  ) as any;

  const checkIfCanSave = () => {
    if (errorMessage === '') {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  };

  useEffect(() => {
    if (errors) {
      const invalidFields = Object.keys(errors);
      logger.debug(`invalidFields: ${JSON.stringify(invalidFields)}`);
      if (invalidFields.length > 0) {
        setErrorMessage(
          `The following fields are set incorrectly: ${JSON.stringify(
            invalidFields
          )}`
        );
        checkIfCanSave();
      } else {
        setErrorMessage('');
        checkIfCanSave();
      }
    }
  });

  useEffect(() => {
    const checkIfTDCredsExistForAccountId = async (
      account_id: string
    ): Promise<boolean | null> => {
      try {
        const credsRef = ref(`/credentials/${account_id}`);
        const credsSnap = await get(credsRef);
        const creds = [];
        credsSnap.forEach((v) => {
          creds.push({ ...v.val() });
        });

        logger.debug(
          `[checkIfTDCredsExistForAccountId] creds.length: ${creds.length}, creds: ${creds}`
        );
        if (creds.length > 0) {
          return true;
        }
      } catch (e) {
        return null;
      }

      return false;
    };

    logger.debug(`useEffect hasCredsCheck - hasCreds: ${hasCreds}`);

    (async () => {
      if (hasCreds === null) {
        const key = formik.values['key'];
        logger.debug(`useEffect hasCredsCheck - key: ${key}`);
        const hasCredsResult = await checkIfTDCredsExistForAccountId(key);

        logger.debug(
          `useEffect hasCredsCheck - hasCredsResult: ${hasCredsResult} (${typeof hasCredsResult})`
        );
        if (hasCredsResult !== null) {
          setHasCreds(hasCredsResult);
        }
      }
    })();
  }, []);

  if (!enumerations || enumerationsLoading) {
    return <Loader loading={true} />;
  }

  const treasureDataEndpoints =
    (Object.values(enumerations.treasureDataEndpoints) as any) || [];

  const handleNameChange = (e) => {
    logger.debug(
      `[TreasureDataFields] handleNameChanged: e.target.value: ${e.target.value}`
    );
    const nameClean = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
    formik.setFieldValue('account_name', nameClean);
  };

  // active/inactive toggle confirmation modal
  const toggle = (ok?) => {
    if (ok === false) {
      formik.setFieldValue('active', !formik.values['active']); // Maybe look at default here instead
    }
    setModal(!modal);
  };

  // this is called when trying to toggle active. It opens the confirmation modal and stores the desired active value
  const confirmSetActive = (value: boolean) => {
    toggle();
  };

  // this is called when the confirm modal button is clicked. It sets the active value and closes the modal.
  const setActiveConfirmed = () => {
    toggle();
  };

  const setActiveCancelled = () => {
    toggle(false);
  };

  const disableIfInactive = formik.values['active'] === false ? true : false;
  const disableAccountId = disableIfInactive || hasCreds === true;

  return (
    <React.Fragment>
      {errorMessage !== '' ? (
        <Row>
          <Col sm={12}>
            <Alert color="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      ) : null}
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <Field
            name="account_name"
            label="Account Name"
            onChange={handleNameChange}
            required
            disabled={disableIfInactive}
          />
          <Field
            name="account_id"
            label="Treasure Data Account Id"
            required
            disabled={disableAccountId}
          />
          <SelectField
            name="api_endpoint"
            label="TD API Endpoint"
            options={treasureDataEndpoints}
            isMulti={false}
            required
            isDisabled={disableIfInactive}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Can I="delete" an="account">
            <FormGroup check>
              <Label check className={accountStyles.activeCheckboxLabel}>
                <Field
                  className={accountStyles.activeSTCheckbox}
                  name="active"
                  type="checkbox"
                  onChange={(e) => {
                    confirmSetActive(e.target.checked);
                  }}
                />{' '}
                Active
              </Label>
            </FormGroup>
          </Can>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={(e) => toggle(false)}>
        <ModalHeader toggle={(e) => toggle(false)}>Please Confirm</ModalHeader>
        <ModalBody>
          Please confirm that you would like to change the 'active' status of
          this account. Once saved this change will also update all of the Vault
          credentials' active flag for this account.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => setActiveConfirmed()}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => setActiveCancelled()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
