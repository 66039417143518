import moment from 'moment';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useObjectVal } from 'react-firebase-hooks/database';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  Button,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { AbilityContext, Can } from '../../auth/Can';
import {
  ArchiveIcon,
  DeleteIcon,
  PauseIcon,
  PlayIcon,
  UnarchiveIcon,
} from '../../components/Icons';
import { Loader } from '../../components/Loader';
import { ColumnsSelector } from '../../components/table/ColumnsSelector';
import { DefaultColumnFilter } from '../../components/table/DefaultFilter';
import { logger } from '../../logging';
import { getDAMApp } from '../../services/firebase';
import {
  State,
  WorkflowState,
  WorkflowSubtypeMap,
  workflowTypesMap,
} from './types';
import { WorkflowModal } from './WorkflowModal';
import { fuzzyTextFilterFnExp } from './WorkflowsContainer';
import { WorkflowsTable } from './WorkflowsTable';
import { getReadableWorkflowLifecycleStage } from './utils';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ref } from '../../utils/firebase';
import { update } from 'firebase/database';
const R = require('ramda');

const pagesStyles = require('../pages.css');
const workflowsStyles = require('./workflows.css');
const iconStyles = require('../../components/icons.css');

const now = moment().unix();

export const Workflows = () => {
  const damApp = getDAMApp();
  const functions = getFunctions(damApp);
  const damAuth = getAuth(damApp);
  const TABLE_NAME = 'workflows';
  const [user, userLoading] = useAuthState(damAuth as any);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
    if (deleteModal === false) {
      workflowToDelete(null);
    }
  };

  const workflowsRef = ref('workflows');
  const [workflowsList, loading, error] = useObjectVal(workflowsRef as any);
  const [values, setValues] = useState([]);
  const [valuesLoading, setValuesLoading] = useState(false);

  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
  const uid = damAuth.currentUser.uid;
  const userColsRef = ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  const [workflow, setWorkflow] = useState(null);
  const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);

  const ability = useContext(AbilityContext);
  const isWorkflowsAdmin = ability.can('manage', 'workflows_admin');
  const isPrivacyWorkflowsAdmin = ability.can('manage', 'privacy_workflows');

  // when workflowsList changes
  useEffect(() => {
    if (isWorkflowModalOpen === true) {
      return;
    }

    let isMounted = true;
    if (workflowsList === null) {
      if (isMounted) {
        setValuesLoading(true);
        setValues([]);
      }
    }

    if (workflowsList) {
      const workflows = Object.keys(workflowsList).filter((k) => {
        return workflowsList[k];
      });

      if (isMounted) {
        setValuesLoading(true);

        const data = workflows.map((k) => {
          const value = workflowsList[k];
          const labels = { ...value };
          return { ...labels, key: k };
        });

        setValues(data);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [workflowsList]);

  useEffect(() => {
    if (isWorkflowModalOpen === false) {
      logger.debug(`workflowModal has been closed. now reload the values`);

      let isMounted = true;
      if (workflowsList) {
        const workflows = Object.keys(workflowsList).filter((k) => {
          return workflowsList[k];
        });

        if (isMounted) {
          setValuesLoading(true);
          setValues(
            workflows.map((k) => {
              const value = workflowsList[k];
              const labels = { ...value };
              return { ...labels, key: k };
            })
          );
        }
      }

      return () => {
        isMounted = false;
      };
    }
  }, [isWorkflowModalOpen]);

  useEffect(() => {
    setValuesLoading(false);
  }, [values]);

  const updateData = async (
    rowIndex,
    columnId,
    value,
    updateTableData,
    key
  ) => {
    const workflowsRef = ref(`workflows/${key}`);
    const workflow = {};
    workflow[columnId] = value;
    workflow['modified'] = now;
    try {
      await update(workflowsRef, workflow);
    } catch (e) {
      logger.error('[updateMyData] - error updating workflow row: ', e);
    }

    if (updateTableData) {
      logger.debug(`setting values 3`);
      setValues((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              ...workflow,
            };
          }
          return row;
        })
      );
    }
  };

  const handleStateChange = async (
    state: WorkflowState,
    workflowid: string
  ) => {
    try {
      logger.debug(
        `[handleStateChange] [${workflowid}] changing to state: ${state}`
      );
      const callable = httpsCallable(
        functions,
        'accountManager-callableUpdateWorkflowState'
      );
      await callable({ id: workflowid, state });
    } catch (e) {
      logger.error(
        `[handleStateChange] - error changing state for workflow id: ${workflowid} to state: ${state} - ${e}`
      );
    }
  };

  const handleDeleteWorkflow = (rowIndex: number, workflowid: string) => {
    logger.debug(
      `[handleDeleteWorkflow] rowIndex: ${rowIndex} workflowid: ${workflowid}`
    );
    // open modal
    setWorkflowToDelete({ rowIndex, workflowid });
    setDeleteModal(true);
  };

  const unarchiveWorkflow = async (rowIndex: number, key: string) => {
    logger.debug(`[unarchiveWorkflow] rowIndex: ${rowIndex} `);
    await updateData(rowIndex, 'isArchived', false, true, key);
  };

  const archiveWorkflow = async (rowIndex: number, key: string) => {
    logger.debug(`[unarchiveWorkflow] rowIndex: ${rowIndex} `);
    await updateData(rowIndex, 'isArchived', true, true, key);
  };

  const deleteWorkflow = async () => {
    setDeleting(true);
    const { rowIndex, workflowid } = workflowToDelete;
    logger.debug(
      `[deleteWorkflow] rowIndex: ${rowIndex} workflowid: ${workflowid}`
    );
    // delete the workflow
    try {
      const callable = httpsCallable(
        functions,
        'accountManager-callableDeleteWorkflow'
      );
      await callable(workflowid);
    } catch (e) {
      logger.error(
        `[deleteWorkflow] - error deleting workflow id: ${workflowid} - ${e}`
      );
    }

    const data = [...values];
    data.splice(rowIndex, 1);
    logger.debug(`setting values 4 (delete)`);
    setValues(data);
    setDeleting(false);
    setDeleteModal(false);
  };

  const handleRowClick = (row, cell, e) => {
    logger.debug(
      `[handleRowClick] row: ${JSON.stringify(row.original)} cell: ${
        cell.column.id
      }`
    );
    if (cell.column.id === 'actions') {
      return;
    }

    setWorkflow(row.original);
    setIsWorkflowModalOpen(true);
  };

  const RenderBooleanCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    return value === 'true' ? 'True' : 'False';
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'key',
        id: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
      },
      {
        Header: 'Actions',
        id: 'actions',
        Cell: ({ row }) => {
          const [loading, setLoading] = useState(false);
          const status = row.original.status;
          const isPrivacy =
            (row.original.args && row.original.args.is_privacy) || false;
          const createdByEmail =
            row.original.createdBy && row.original.createdBy.email;
          const canResume =
            isPrivacy === false ||
            (isPrivacy === true &&
              user.email !== createdByEmail &&
              isPrivacyWorkflowsAdmin &&
              row.original.endDate >= now);
          const resumeDisabled =
            status === State.ENABLED || !canResume ? true : false;
          const deleteDisabled = status === State.ENABLED ? true : false;
          const pauseDisabled = status !== State.ENABLED ? true : false;
          const runNowDisabled = status !== State.ENABLED ? true : false;

          const handleStateChangeClick = async (
            state: WorkflowState,
            workflowid: string
          ) => {
            setLoading(true);
            await handleStateChange(state, workflowid);
            setLoading(false);
          };

          const canArchive = isWorkflowsAdmin && status !== State.ENABLED;

          if (row.original.isArchived === true) {
            return (
              <div className={workflowsStyles.actionsRow}>
                <Button
                  className={workflowsStyles.actionButton}
                  title="Unarchive"
                  onClick={() => unarchiveWorkflow(row.index, row.original.key)}
                  disabled={!isWorkflowsAdmin}
                >
                  <UnarchiveIcon fill="white" class={iconStyles.unarchive} />
                </Button>
              </div>
            );
          } else {
            return (
              <div className={workflowsStyles.actionsRow}>
                <Button
                  className={workflowsStyles.actionButton}
                  title="Resume"
                  disabled={resumeDisabled}
                  onClick={() =>
                    handleStateChangeClick(
                      WorkflowState.RESUME,
                      row.original.key
                    )
                  }
                >
                  <PlayIcon fill="white" class={iconStyles.tick} />
                </Button>

                <Button
                  className={workflowsStyles.actionButton}
                  title="Pause"
                  disabled={pauseDisabled}
                  onClick={() =>
                    handleStateChangeClick(
                      WorkflowState.PAUSE,
                      row.original.key
                    )
                  }
                >
                  <PauseIcon fill="white" class={iconStyles.tick} />
                </Button>

                <Button
                  className={workflowsStyles.actionButton}
                  title="Run Now"
                  disabled={runNowDisabled}
                  onClick={() =>
                    handleStateChangeClick(
                      WorkflowState.RUN_NOW,
                      row.original.key
                    )
                  }
                >
                  {'Run Now'}
                </Button>

                <Button
                  className={workflowsStyles.actionButton}
                  title="Delete"
                  disabled={deleteDisabled}
                  onClick={() =>
                    handleDeleteWorkflow(row.index, row.original.key)
                  }
                >
                  <DeleteIcon fill="white" class={iconStyles.tick} />
                </Button>

                <Can I="manage" a="workflows_admin">
                  <Button
                    className={workflowsStyles.actionButton}
                    title="Archive"
                    onClick={() => archiveWorkflow(row.index, row.original.key)}
                    disabled={!canArchive}
                  >
                    <ArchiveIcon fill="white" class={iconStyles.archive} />
                  </Button>
                </Can>

                <Loader loading={loading} />
              </div>
            );
          }
        },
      },
      {
        Header: 'Last Execution',
        accessor: (row) => {
          if (row.last_execution) {
            return moment
              .unix(parseInt(row.last_execution))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'last_execution',
      },
      {
        Header: 'Last Execution Result',
        accessor: (row) => {
          if (row.last_execution_result) {
            return row.last_execution_result;
          }

          return '';
        },
        id: 'last_execution_result',
      },
      {
        Header: 'Lifecycle Stage',
        accessor: getReadableWorkflowLifecycleStage,
        id: 'lifecycle_stage',
      },
      {
        Header: 'Scheduler Status',
        accessor: 'status',
        id: 'status',
      },
      {
        Header: 'Error',
        accessor: (row) =>
          row.last_execution_result === 'error' ? row.error : '',
        id: 'error',
      },
      {
        Header: 'Created',
        accessor: (row) => {
          if (row.created) {
            return moment
              .unix(parseInt(row.created))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'created',
      },
      {
        Header: 'Created By',
        accessor: (row) => {
          if (row.createdBy) {
            return row.createdBy.email;
          }

          return '';
        },
        id: 'createdBy',
        hideHeader: true,
      },
      {
        Header: 'Modified',
        accessor: (row) => {
          if (row.modified) {
            return moment
              .unix(parseInt(row.modified))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'modified',
      },
      {
        Header: 'Type',
        accessor: (row) => {
          const type = workflowTypesMap[row.type];
          return type || '';
        },
        id: 'type',
      },
      {
        Header: 'Subtype',
        accessor: (row) => {
          const type = WorkflowSubtypeMap[row.subtype];
          return type || '';
        },
        id: 'subtype',
      },
      {
        Header: 'Description',
        accessor: 'description',
        id: 'description',
      },
      {
        Header: 'End Date',
        accessor: (row) => {
          if (row.endDate) {
            return moment.unix(row.endDate).format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'endDate',
      },
      {
        Header: 'Archived',
        accessor: (row) => {
          if (row.isArchived === true) {
            return 'true';
          }

          return 'false';
        },
        Cell: RenderBooleanCell,
        id: 'isArchived',
      },
      {
        Header: 'Privacy',
        accessor: (row) => {
          if (row.args && row.args.is_privacy === true) {
            return 'true';
          }

          return 'false';
        },
        Cell: RenderBooleanCell,
        id: 'is_privacy',
      },
      {
        Header: 'Store',
        accessor: (row) => {
          if (row.args && row.args.is_store === true) {
            return 'true';
          }

          return 'false';
        },
        Cell: RenderBooleanCell,
        id: 'is_store',
      },
    ],
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize values - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => values, [values]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const tableData = useTable(
    {
      columns,
      data: records,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'created',
            desc: true,
          },
        ],
        filters: [
          {
            id: 'isArchived',
            value: 'false',
          },
        ],
        pageSize: 20,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
      updateData,
      handleRowClick,
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  React.useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  if (loading || valuesLoading || userColsLoading || userLoading) {
    return <Loader loading={true} />;
  }

  if (error) {
    return <div>{`Error: ${error.message}`}</div>;
  }

  if (userColsError) {
    return <div>Error loading cols</div>;
  }

  if (!user) {
    return <div>Error loading current user</div>;
  }

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);
  const toggleWorkflowModal = () =>
    setIsWorkflowModalOpen(!isWorkflowModalOpen);

  return (
    <div className={pagesStyles.fullHeight}>
      {values && (
        <div className={`${pagesStyles.noPadding} ${pagesStyles.fullHeight}`}>
          <Row className={pagesStyles.headerRow}>
            <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
              <Button
                onClick={toggleWorkflowModal}
                className={workflowsStyles.topButtons}
              >
                Create New Workflow
              </Button>
              <Button
                onClick={toggleColumnsSelect}
                className={workflowsStyles.topButtons}
              >
                Columns
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Collapse isOpen={isColumnsSelectOpen}>
                <ColumnsSelector
                  columns={columns}
                  userCols={userCols}
                  tableName={TABLE_NAME}
                />
              </Collapse>
            </Col>
          </Row>
          <Row className={pagesStyles.fullHeight}>
            <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
              <Can I="manage" a="workflows">
                <WorkflowsTable {...tableData} />
              </Can>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 12 }}>
              <Can I="manage" a="workflows">
                <Modal isOpen={deleteModal} toggle={(e) => toggleDeleteModal()}>
                  <ModalHeader toggle={(e) => toggleDeleteModal()}>
                    Please Confirm
                  </ModalHeader>
                  <ModalBody>
                    {`Please confirm that you'd like to delete workflow: ${
                      (workflowToDelete &&
                        workflowToDelete.workflowid &&
                        workflowToDelete.workflowid) ||
                      ''
                    }`}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={() => deleteWorkflow()}
                      disabled={deleting}
                    >
                      Confirm
                    </Button>{' '}
                    <Button
                      color="secondary"
                      onClick={(e) => toggleDeleteModal()}
                      disabled={deleting}
                    >
                      Cancel
                    </Button>
                    <Loader loading={deleting} />
                  </ModalFooter>
                </Modal>
              </Can>
            </Col>
          </Row>
        </div>
      )}
      <Can I="manage" a="workflows">
        {isWorkflowModalOpen && (
          <Row className={pagesStyles.fullHeight}>
            <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
              <WorkflowModal
                workflow={workflow}
                setWorkflow={setWorkflow}
                isOpen={isWorkflowModalOpen}
                toggle={setIsWorkflowModalOpen}
                user={user}
                isWorkflowsAdmin={isWorkflowsAdmin}
              />
            </Col>
          </Row>
        )}
      </Can>
    </div>
  );
};
