import { useEffect, useState } from 'react';
import * as firebaseService from '../../services/firebase';
import { logger } from '../../logging';
import {
  equalTo,
  get,
  getDatabase,
  orderByChild,
  query,
  ref,
} from 'firebase/database';

export enum BrandService {
  CANOPUS = 'CANOPUS',
  PARTY_SERVICE = 'PARTY_SERVICE',
}

interface Brand {
  name: string;
  canopus_id?: string | number;
}

const getBrand = async (
  brandId: number | string,
  brandService: BrandService
): Promise<Brand> => {
  const brand = {
    name: '',
  };

  if (brandService === BrandService.CANOPUS) {
    logger.debug(`[getBrand] canopusId: ${brandId}`);
    const canopusApp = firebaseService.getCanopusApp();
    const canopusDB = getDatabase(canopusApp);
    const canopusRef = ref(canopusDB, 'canopus');
    const canopusQuery = query(
      canopusRef,
      orderByChild('canopusId'),
      equalTo(`${brandId}`)
    );

    try {
      const canopusSnap = await get(canopusQuery);
      canopusSnap.forEach((snap) => {
        const canopusNameChild = snap.child('canopusName').val();
        if (typeof canopusNameChild === 'string' && canopusNameChild !== '') {
          logger.debug(`[getBrand] setting canopusName: ${canopusNameChild}`);
          brand['name'] = canopusNameChild;
          return true; // exit the loop after finding the first matching canopus name
        }
      });
    } catch (e) {
      logger.error('getBrand: error calling firebase:', e);
    }
  } else {
    const brandIdAsNumber = parseInt(brandId as string, 10);
    if (isNaN(brandIdAsNumber)) {
      return brand;
    }

    const partyServiceApp = firebaseService.getPartyServiceApp();
    const partyServiceDB = getDatabase(partyServiceApp);
    const partyServiceRef = ref(partyServiceDB, 'party_id');
    const partyServiceQuery = query(
      partyServiceRef,
      orderByChild('party_id'),
      equalTo(brandIdAsNumber)
    );

    try {
      const partyServiceSnap = await get(partyServiceQuery);
      partyServiceSnap.forEach((snap) => {
        const partySnapVal = snap.val();
        const partyServiceName = partySnapVal['party_name'];
        logger.debug(
          `[getBrand] partyServiceName: ${partyServiceName}, partySnapVal['canopus_id']: ${partySnapVal['canopus_id']}`
        );
        if (typeof partyServiceName === 'string' && partyServiceName !== '') {
          brand['name'] = partyServiceName;
          brand['canopus_id'] = partySnapVal['canopus_id'];
          return true; // exit the loop after finding the first matching party service name
        }
      });
    } catch (e) {
      logger.error('getBrand: error calling firebase:', e);
    }
  }

  return brand;
};

export const useBrand = (
  brandId: number | string,
  brandService: BrandService
) => {
  const [brand, setBrand] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    // logger.debug(`[useBrand] [useEffect] brandId: ${brandId}`);
    const getBrandCall = getBrand(brandId, brandService);

    if (brandId) {
      setLoading(true);
    }

    getBrandCall
      .then((brand) => {
        // logger.debug(
        //   `[useBrand] [useEffect] brand.name: ${brand.name}, brand.canopus_id: ${brand.canopus_id}`
        // );
        setBrand(brand);
        setLoading(false);
      })
      .catch((e) => {
        logger.error('[useBrand] [useEffect] error: calling firebase:', e);
        setError(e);
        setLoading(false);
      });
  }, [brandId]);
  return [brand, loading, error];
};
