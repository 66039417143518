import * as React from 'react';
import { useContext, useState } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import * as firebaseService from '../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  TabPane,
} from 'reactstrap';
import { Loader } from '../../components/Loader';
import { logger, serverLogger } from '../../logging';
import { ref } from '../../utils/firebase';
import { DATA_ACQUISITION_TAB } from './AccountForm';
import moment from 'moment';
import { AbilityContext } from '../../auth/Can';
import * as R from 'ramda';
import { getDAMApp } from '../../services/firebase';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { get, getDatabase, update } from 'firebase/database';

const accountStyles = require('./account.css');
enum DataAcquisitionCDPDestinations {
  NONE = 'none',
  LYTICS = 'lytics',
  TREASURE_DATA = 'treasure_data',
  BOTH = 'both',
  UNSELECTED = 'unselected',
}

export const DataAcquisitionTab = ({ system, account }) => {
  const damApp = firebaseService.getDAMApp();
  const damAuth = getAuth(damApp);
  const [user, userLoading] = useAuthState(damAuth as any);
  const [accountValues, accountLoading, accountError] = useObjectVal(
    ref(`accounts/${system}/${account.id}`)
  );
  const [country, countryLoading, countryError] = useObjectVal(
    ref(
      `countries/${
        system === 'dotdigital'
          ? account.territoryOperatingCountry
          : account.country
      }`
    )
  );

  const [saving, setSaving] = useState(false);
  const [dataAcquisitionSelection, setDataAcquisitionSelection] = useState(
    DataAcquisitionCDPDestinations.UNSELECTED
  );

  const [dataAcquisitionProcessingMethod, setDataAcquisitionProcessingMethod] =
    useState('asynchronous');

  const [sendToCDP, setSendToCDP] = useState(false);
  const [createLyticsEntities, setCreateLyticsEntities] = useState(false);

  // looks like this is always popped up if trying to save regardless of options changed
  const [modal, setModal] = useState(false);
  const [needsDDCreds, setNeedsDDCreds] = useState(false);
  const [hasDDCreds, setHasDDCreds] = useState(false);
  const [checkingCreds, setCheckingCreds] = useState(false);

  const ability = useContext(AbilityContext);

  const toggle = (ok?) => {
    if (ok === true) {
      // logger.debug(`modal confirmed. save now.`);
    }
    setModal(!modal);
  };

  React.useEffect(() => {
    if (system !== 'dotdigital') {
      return;
    }

    if (
      dataAcquisitionProcessingMethod === 'legacy' &&
      dataAcquisitionSelection === DataAcquisitionCDPDestinations.LYTICS
    ) {
      setNeedsDDCreds(false);
    } else {
      setNeedsDDCreds(true);
    }
  }, [dataAcquisitionSelection, dataAcquisitionProcessingMethod]);

  React.useEffect(() => {
    if (system !== 'dotdigital') {
      return;
    }

    const checkIfDDCredsExistForAccountId = async () => {
      setCheckingCreds(true);
      // do the async check
      try {
        const damApp = getDAMApp();
        const functions = getFunctions(damApp);
        const credsRef = ref(`/credentials/${account.id}`);
        const credsSnap = await get(credsRef);
        const ifbCreds = [];
        credsSnap.forEach((v) => {
          if (v.val().name === 'form-builder' && v.val().active === true) {
            ifbCreds.push({ ...v.val() });
          }
        });

        if (ifbCreds.length > 0) {
          // logger.debug(
          //   `[checkIfDDCredsExistForAccountId] creds.length: ${ifbCreds.length}, creds: ${ifbCreds}`
          // );

          // now attempt to validate cred is ok
          const callable = httpsCallable(
            functions,
            'accountManager-callableCheckDDAccountCredentialValid'
          );

          await callable({ accountId: account.ddAccountId });
          // logger.debug(
          //   `[DataAcquisitionTab] checkIfDDCredsExistForAccountId returning true`
          // );
          setCheckingCreds(false);
          return true;
        }
      } catch (e) {
        setCheckingCreds(false);
        // logger.debug(
        //   `[DataAcquisitionTab] checkIfDDCredsExistForAccountId error so returning false`
        // );
        return false;
      }

      setCheckingCreds(false);
      // logger.debug(
      //   `[DataAcquisitionTab] checkIfDDCredsExistForAccountId returning false`
      // );
      return false;
    };

    if (needsDDCreds) {
      (async () => {
        try {
          const hasCreds = await checkIfDDCredsExistForAccountId();
          // logger.debug(
          //   `[DataAcquisitionTab] UseEffect: hasCredsResult returned without error so setting hasDDCreds to ${hasCreds}`
          // );
          setHasDDCreds(hasCreds);
        } catch (e) {
          // logger.error(
          //   `[DataAcquisitionTab] UseEffect: error calling checkIfDDCredsExistForAccountId: ${e}`
          // );
          setHasDDCreds(false);
        }
      })();
    }
  }, [needsDDCreds]);

  React.useEffect(() => {
    if (accountValues && accountValues['data_acquisition_processing_method']) {
      setDataAcquisitionProcessingMethod(
        accountValues['data_acquisition_processing_method']
      );
    }

    if (accountValues && accountValues['data_acquisition_selection']) {
      setDataAcquisitionSelection(accountValues['data_acquisition_selection']);
    }

    if (
      accountValues &&
      accountValues['data_acquisition_processing_method'] &&
      accountValues['data_acquisition_processing_method'] === 'asynchronous' &&
      accountValues['data_acquisition_selection'] &&
      (accountValues['data_acquisition_selection'] ===
        DataAcquisitionCDPDestinations.NONE ||
        accountValues['data_acquisition_selection'] ===
          DataAcquisitionCDPDestinations.UNSELECTED)
    ) {
      setSendToCDP(false);
      setCreateLyticsEntities(false);
    } else {
      setSendToCDP(true);
    }

    // set the createStandardLyticsEntities
    if (
      accountValues &&
      accountValues['createStandardLyticsEntities'] &&
      accountValues['createStandardLyticsEntities'] === true
    ) {
      setCreateLyticsEntities(true);
    }
  }, [accountLoading]);

  if (accountLoading === true || countryLoading || checkingCreds) {
    return <Loader loading={true} />;
  }

  if (accountError || countryError) {
    return (
      <TabPane tabId={DATA_ACQUISITION_TAB}>
        <React.Fragment>Error</React.Fragment>
      </TabPane>
    );
  }

  if (!accountValues) {
    return (
      <TabPane tabId={DATA_ACQUISITION_TAB}>
        <div>No data available for account</div>
      </TabPane>
    );
  }

  const dataAcquisitionProcessingOptions = R.map(
    (type: any) => {
      const capitalized = type.charAt(0).toUpperCase() + type.slice(1);
      return (
        <option value={type} key={type}>
          {capitalized}
        </option>
      );
    },
    ['legacy', 'asynchronous']
  );

  const handleFieldChanged = (e) => {
    const val = e.target.value;
    setDataAcquisitionSelection(val);
    if (
      val === DataAcquisitionCDPDestinations.LYTICS ||
      val === DataAcquisitionCDPDestinations.BOTH
    ) {
      setCreateLyticsEntities(true);
    } else {
      setCreateLyticsEntities(false);
    }
  };

  const handleDataAcquisitionMethodChanged = (e) => {
    const val = e.target.value;
    if (val === 'legacy') {
      setDataAcquisitionSelection(DataAcquisitionCDPDestinations.LYTICS);
      setCreateLyticsEntities(true);
    } else if (val === 'asynchronous') {
      setDataAcquisitionSelection(DataAcquisitionCDPDestinations.UNSELECTED);
      setCreateLyticsEntities(false);
    }
    setDataAcquisitionProcessingMethod(val);
  };

  const saveDataAcquisitionFields = async () => {
    // logger.debug(`[DataAcquisitionTab] save clicked`);
    setSaving(true);
    toggle(true);
    if (accountValues && !userLoading && user) {
      try {
        const accountRef = ref(`accounts/${system}/${account.id}`);
        const accountToUpdate = {
          ...accountValues,
          lastModifiedBy: { uid: user.uid, email: user.email },
        };

        accountToUpdate['data_acquisition_selection'] =
          dataAcquisitionSelection === DataAcquisitionCDPDestinations.UNSELECTED
            ? DataAcquisitionCDPDestinations.NONE
            : dataAcquisitionSelection;

        if (dataAcquisitionProcessingMethod === 'legacy') {
          // if you have changed to legacy the cdp must be lytics
          accountToUpdate['data_acquisition_selection'] = 'lytics';
          // if not async make sure insights set to false;
          accountToUpdate['send_subscription_insights'] = false;
          accountToUpdate['send_genre_insights'] = false;
        }

        accountToUpdate['data_acquisition_processing_method'] =
          dataAcquisitionProcessingMethod;

        if (system === 'dotdigital') {
          accountToUpdate['createStandardLyticsEntities'] =
            createLyticsEntities;
          accountToUpdate['lastModifiedAt'] = `${moment().format()}`;
        }

        if (system === 'slicktext') {
          accountToUpdate['modified'] = `${moment().unix()}`;
        }

        serverLogger(
          `[Data Acquitision Tab] User ${user.email} attempting to change setting for account ${system}/${account.id} to data_acquisition_selection: ${dataAcquisitionSelection}`,
          'info'
        );

        await update(accountRef, accountToUpdate);
      } catch (e) {
        logger.error(`[DataAcquisitionTab] error saving account update: ${e}`);
      }
    }
    setSaving(false);
  };

  const handleSendToCDP = (e) => {
    // use ref to set approprietly
    // logger.debug(`[handleSendToCDP] e.target.checked: ${e.target.checked}`);

    if (e.target.checked === false) {
      setSendToCDP(false);
      setDataAcquisitionProcessingMethod('asynchronous');
      setDataAcquisitionSelection(DataAcquisitionCDPDestinations.NONE);
      setCreateLyticsEntities(false);
    } else {
      setSendToCDP(true);
      // what do we default to here? if anything?
    }
  };

  const handleCreateLyticsEntities = (e) => {
    if (e.target.checked === false) {
      setCreateLyticsEntities(false);
    } else {
      setCreateLyticsEntities(true);
    }
  };

  const canSetTreasureData =
    country &&
    country['treasure_data_instance'] &&
    country['treasure_data_instance'] !== '';

  const sendToCDPEnabledButNotSelected =
    sendToCDP === true &&
    dataAcquisitionProcessingMethod === 'asynchronous' &&
    dataAcquisitionSelection === DataAcquisitionCDPDestinations.NONE;
  const invalidSelection =
    (dataAcquisitionSelection !== DataAcquisitionCDPDestinations.NONE &&
      sendToCDP === false) ||
    (sendToCDP === false && dataAcquisitionProcessingMethod === 'legacy') ||
    dataAcquisitionSelection === DataAcquisitionCDPDestinations.UNSELECTED;

  return (
    <TabPane tabId={DATA_ACQUISITION_TAB}>
      <Row className={accountStyles.formRow} sm="3">
        <Col>
          <FormGroup switch>
            <Input
              type="switch"
              id="send_to_cdp"
              name="send_to_cdp"
              onChange={handleSendToCDP}
              checked={sendToCDP}
            />
            <Label for="send_to_cdp">Send to CDP</Label>
          </FormGroup>
        </Col>
      </Row>
      {invalidSelection && !sendToCDPEnabledButNotSelected && (
        <Row>
          <Col sm="6">
            <Alert color="warning" className={accountStyles.sendToCDPWarning}>
              Please select a valid Data Acquisition Selection and Data
              Acquisition method combination
            </Alert>
          </Col>
        </Row>
      )}
      {sendToCDPEnabledButNotSelected && (
        <Row>
          <Col sm="6">
            <Alert color="warning" className={accountStyles.sendToCDPWarning}>
              Please select a valid Data Acquisition Selection which will send
              to CDP
            </Alert>
          </Col>
        </Row>
      )}
      {needsDDCreds === true && hasDDCreds === false ? (
        <Row>
          <Col sm="6">
            <Alert color="warning" className={accountStyles.sendToCDPWarning}>
              This setting requires IFB DotDigital credentials.
            </Alert>
          </Col>
        </Row>
      ) : null}
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <FormGroup>
            <Label>Data Acquisition Method</Label>
            <Input
              type="select"
              name="data_acquisition_processing_method"
              placeholder="Data Acquisition Method"
              value={dataAcquisitionProcessingMethod}
              onChange={handleDataAcquisitionMethodChanged}
              disabled={sendToCDP === false}
            >
              {dataAcquisitionProcessingOptions}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <FormGroup>
            <Label>Data Acquisition Selection</Label>
            <Input
              type="select"
              name="data_acquisition_selection"
              placeholder="Data Acquisition Selection"
              value={dataAcquisitionSelection}
              onChange={handleFieldChanged}
              disabled={
                dataAcquisitionProcessingMethod !== 'asynchronous' ||
                sendToCDP === false
              }
            >
              <option value={DataAcquisitionCDPDestinations.NONE}>
                Campaign tool only
              </option>
              {dataAcquisitionSelection ===
                DataAcquisitionCDPDestinations.UNSELECTED && (
                <option value={DataAcquisitionCDPDestinations.UNSELECTED}>
                  Please Select
                </option>
              )}
              <option value={DataAcquisitionCDPDestinations.LYTICS}>
                Lytics
              </option>
              {canSetTreasureData && (
                <option value={DataAcquisitionCDPDestinations.TREASURE_DATA}>
                  Treasure Data
                </option>
              )}
              {canSetTreasureData && (
                <option value={DataAcquisitionCDPDestinations.BOTH}>
                  Both
                </option>
              )}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      {system === 'dotdigital' ? (
        <Row className={accountStyles.formRow} sm="3">
          <Col>
            <FormGroup switch>
              <Input
                type="switch"
                id="createStandardLyticsEntities"
                name="createStandardLyticsEntities"
                onChange={handleCreateLyticsEntities}
                checked={createLyticsEntities}
                disabled={true}
              />
              <Label for="createStandardLyticsEntities">
                Create Acquisition Sync/Contacts and Activity import (Lytics)
              </Label>
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <Button
            color="primary"
            onClick={(e) => toggle(true)}
            disabled={
              ability.cannot('manage', 'data_acquisition') ||
              saving ||
              sendToCDPEnabledButNotSelected ||
              dataAcquisitionSelection ===
                DataAcquisitionCDPDestinations.UNSELECTED ||
              invalidSelection
            }
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={(e) => toggle(false)}>
        <ModalHeader toggle={(e) => toggle(false)}>Please Confirm</ModalHeader>
        <ModalBody>
          Please confirm that you would like to change the data acquisition
          method.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => saveDataAcquisitionFields()}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => toggle(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </TabPane>
  );
};
