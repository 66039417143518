import * as React from 'react';
import { useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';

import { LoginForm } from './login/LoginForm';
import { LocationProps } from '../Routes';
import { Loader } from '../components/Loader';
import { useUserWithRole } from '../hooks/firebase';
import * as firebaseService from '../services/firebase';
import { logger, serverLogger } from '../logging';
import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  signInWithCredential,
} from 'firebase/auth';

export const Login = () => {
  const canopusApp = firebaseService.getCanopusApp();
  const ifbApp = firebaseService.getIFBApp();
  const damApp = firebaseService.getDAMApp();
  const partyServiceApp = firebaseService.getPartyServiceApp();
  const damAuth = getAuth(damApp);
  const canopusAuth = getAuth(canopusApp);
  const ifbAuth = getAuth(ifbApp);
  const partyServieAuth = getAuth(partyServiceApp);

  const location: LocationProps = useLocation();
  const [user, loading, error] = useUserWithRole(damAuth);
  const [userIFB, loadingIFB, errorIFB] = useUserWithRole(ifbAuth);
  const [userCanopus, loadingCanopus, errorCanopus] =
    useUserWithRole(canopusAuth);
  const [userPartyService, loadingPartyService, errorPartyService] =
    useUserWithRole(partyServieAuth);
  const [loginError, setLoginError] = useState();
  const [cred, setCred]: [any, any] = useState();

  const { from } = location.state || { from: { pathname: '/accounts' } };

  const loginWithAd = async () => {
    setLoginError(undefined);

    try {
      // sign in with AD
      const provider = new OAuthProvider('microsoft.com');
      logger.debug(
        `provider: ${typeof provider} - ${JSON.stringify(provider)}`
      );
      provider.setCustomParameters({
        tenant: process.env.TENANT_ID,
      });

      const result = await signInWithPopup(damAuth, provider);
      const cred = OAuthProvider.credentialFromResult(result);
      setCred(cred);
    } catch (e) {
      logger.error('error logging in ', e);
      serverLogger(`Error logging in: ${e}`, 'error');
      setLoginError(e);
    }
  };

  if (loading || loadingCanopus || loadingIFB || loadingPartyService) {
    return <Loader loading={true} />;
  }

  if (
    typeof error !== 'undefined' ||
    typeof errorCanopus !== 'undefined' ||
    typeof errorIFB !== 'undefined' ||
    typeof errorPartyService !== 'undefined'
  ) {
    return (
      <LoginForm errorMessage={(error as Error).message} login={loginWithAd} />
    );
  }

  if (typeof loginError !== 'undefined') {
    return (
      <LoginForm
        errorMessage={(loginError as Error).message}
        login={loginWithAd}
      />
    );
  }

  if (cred && !userCanopus) {
    signInWithCredential(canopusAuth, cred);
  }

  if (cred && !userIFB) {
    signInWithCredential(ifbAuth, cred);
  }

  if (cred && !userPartyService) {
    signInWithCredential(partyServieAuth, cred);
  }

  // user has access to this app
  if (user && userCanopus && userIFB && userPartyService) {
    const userEmail = user['email'];
    // logger.info('reidrecting to', from.pathname);
    serverLogger(`User logged in: ${userEmail}`, 'info');
    return (
      <Redirect to={{ pathname: from.pathname, state: { from: location } }} />
    );
  }

  return <LoginForm login={loginWithAd} />;
};
