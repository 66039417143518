import * as React from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap';
import { useList } from 'react-firebase-hooks/database';
import { ref } from '../../utils/firebase';
import { EmailCategoryRow } from './EmailCategoryRow';
import { logger } from '../../logging';
import * as firebaseService from '../../services/firebase';
import { Loader } from '../../components/Loader';
import { getFunctions, httpsCallable } from 'firebase/functions';

const pagesStyles = require('../pages.css');
const optinsStyles = require('./optins.css');

const EMAIL_OPTINS_TAB = '1';

export const OptinsCategories = () => {
  const damApp = firebaseService.getDAMApp();
  const functions = getFunctions(damApp);
  const [activeTab, setActiveTab] = useState('1');
  const [emailCategoriesValues, emailCategoriesLoading, emailCategoriesError] =
    useList(ref('enumerations/addressbookCategories') as any);
  const [accountTypesValues, accountTypesLoading, accountTypesError] = useList(
    ref('enumerations/ddAccountTypes') as any
  );
  const [validationError, setValidationError] = useState('');
  const [updateAllAddressbooksModalOpen, setUpdateAllAddressbooksModalOpen] =
    useState(false);
  const [addressbookCategoryToUpdateData, setAddressbookCategoryToUpdateData] =
    useState({
      value: '',
      label: '',
      count: 0,
    });

  const [addressbooksBeingUpdated, setAddressbooksBeingUpdated] =
    useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleUpdateAllAddressbooksModal = () => {
    setUpdateAllAddressbooksModalOpen(!updateAllAddressbooksModalOpen);
  };

  const updateAllAddressbooks = async () => {
    // logger.debug(`[EmailCategoryRow] updateAll called`);
    try {
      setAddressbooksBeingUpdated(true);
      // call callable to update all addressbooks pass refKey
      const callable = httpsCallable(
        functions,
        'accountManager-callableUpdateAddressbookFlags'
      );
      const data = { categoryId: addressbookCategoryToUpdateData['value'] };
      // logger.debug(`calling accountManager-callableUpdateAddressbookFlags with data: ${JSON.stringify(data)}`);
      await callable(data);
      setAddressbooksBeingUpdated(false);
    } catch (e) {
      logger.error(`updateAll: e: ${e}`);
      setAddressbooksBeingUpdated(false);
    }

    toggleUpdateAllAddressbooksModal();
  };

  logger.debug('[OptinsCategories] about to render');
  return (
    <div className={pagesStyles.container}>
      <Row className={pagesStyles.headerRow}>
        <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
          <h3>Optin Categories</h3>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 12 }}>
          <Alert color="warning">
            <p>
              Changing toggles on this screen will affect{' '}
              <b>all category settings</b> on new address books.
            </p>
            <p>
              The <b>‘Update All Addressbooks’</b> Button will apply Toggle
              choices to&nbsp;
              <b>all historical and new address books</b>, in that category.
            </p>
          </Alert>
        </Col>
      </Row>
      {validationError && validationError !== '' && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Alert color="danger">{validationError}</Alert>
          </Col>
        </Row>
      )}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === EMAIL_OPTINS_TAB })}
            onClick={() => {
              toggle(EMAIL_OPTINS_TAB);
            }}
          >
            Email Addressbooks
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={EMAIL_OPTINS_TAB}>
          {emailCategoriesValues && accountTypesValues && (
            <React.Fragment>
              <Row>
                <Col sm={{ size: 12 }}>
                  <Table striped responsive>
                    <tbody>
                      {emailCategoriesValues.map((c) => {
                        if (c.key !== 'unknown_list') {
                          return (
                            <EmailCategoryRow
                              refKey={c.key}
                              val={c.val()}
                              key={c.key}
                              toggleUpdateAllAddressbooksModal={
                                toggleUpdateAllAddressbooksModal
                              }
                              setAddressbookCategoryToUpdateData={
                                setAddressbookCategoryToUpdateData
                              }
                              ddAccountTypes={accountTypesValues}
                            />
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Modal
                  isOpen={updateAllAddressbooksModalOpen}
                  toggle={(e) => toggleUpdateAllAddressbooksModal()}
                  className={optinsStyles.optinsModal}
                >
                  <ModalHeader
                    toggle={(e) => toggleUpdateAllAddressbooksModal()}
                    className={optinsStyles.optinsModalHeader}
                  >
                    Please Confirm Updates of all Addressbooks
                  </ModalHeader>
                  <ModalBody className={optinsStyles.optinsModalBody}>
                    <Row className={optinsStyles.optinsModalBodyRow}>
                      <Col xs={12}>
                        {`Please confirm that you'd like to update the addressbook defaults for all addressbooks 
                              with category: ${addressbookCategoryToUpdateData['label']}`}
                      </Col>
                    </Row>
                    <Row className={optinsStyles.optinsModalBodyRow}>
                      <Col xs={12}>
                        {`Addressbooks to be updated count = ${addressbookCategoryToUpdateData['count']}`}
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter className={optinsStyles.optinsModalFooter}>
                    {addressbooksBeingUpdated && (
                      <Row className={optinsStyles.optinsModalFooterRow}>
                        <Col sm={2}></Col>
                        <Col sm={8}>
                          <Loader loading={true} />
                        </Col>
                        <Col sm={2}></Col>
                      </Row>
                    )}
                    <Row className={optinsStyles.optinsModalFooterRow}>
                      <Col sm={12}>
                        <Button
                          color="primary"
                          onClick={async () => await updateAllAddressbooks()}
                          disabled={addressbooksBeingUpdated}
                        >
                          Confirm
                        </Button>{' '}
                        <Button
                          color="secondary"
                          onClick={(e) => toggleUpdateAllAddressbooksModal()}
                          disabled={addressbooksBeingUpdated}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </ModalFooter>
                </Modal>
              </Row>
            </React.Fragment>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};
