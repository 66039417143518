.activeCheckbox {
  margin: -48px 0 0 -40px;
}

.activeSTCheckbox {
  margin: 6px 0 0 -40px;
}

.activeCheckboxLabel {
  margin: 20px 0 30px 30px;
}

.actionLoading {
  width: 39px;
  height: 39px;
}

.createLoading {
  width: 39px;
  height: 39px;
}

.auditGroup {
  margin: 20px 0 30px 0;
}

.credentialsAuditRow {
  padding: 0 !important;
}

.credsExportRow {
  margin: 20px 0 30px 0;
}

.actionButton {
  margin-right: 20px;
}

.formRow {
  margin-top: 20px;
}

.codesTable {
  margin-top: 50px;
}

.codesSelectRow {
  margin-top: 10px;
}

.codesSave {
  margin-top: 20px;
}

.sendToCDPWarning {
  margin-top: 15px;
}
