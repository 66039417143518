import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import * as firebaseService from '../../services/firebase';
import { Row, Col, Button, Input, Collapse } from 'reactstrap';
import { D2cArtistMappingTable } from './D2cArtistMappingTable';
import { DefaultColumnFilter } from '../../components/table/DefaultFilter';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import { Loader } from '../../components/Loader';
import { fuzzyTextFilterFnExp } from './ArtistMappingContainer';
import { CrossIcon, TickIcon } from '../../components/Icons';
import moment from 'moment';
import { AbilityContext, Can } from '../../auth/Can';
import { logger } from '../../logging';
import { ColumnsSelector } from '../../components/table/ColumnsSelector';
import { getAuth } from 'firebase/auth';
import { ref } from '../../utils/firebase';
import {
  update,
  ref as fbRef,
  getDatabase,
  query,
  orderByChild,
  equalTo,
  get,
} from 'firebase/database';
const R = require('ramda');

const pagesStyles = require('../pages.css');
const artistMappingStyles = require('./artistMapping.css');
const iconStyles = require('../../components/icons.css');
const dotdigitalStyles = require('../account/dotdigital/dotdigital.css');

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const ARTIST_SOURCE = 'd2c_de';

export interface D2cArtist {
  artist_name: string;
  canopus_id?: number;
  crm_status: boolean;
  created: number;
  modified?: number;
}

interface D2cArtists {
  d2cArtists: D2cArtist[];
}

export const getCanopus = async (canopusId: number) => {
  const canopusApp = firebaseService.getCanopusApp();
  const canopusDatabase = getDatabase(canopusApp);
  const canopusRef = fbRef(canopusDatabase, 'canopus');
  const canopusQuery = query(
    canopusRef,
    orderByChild('canopusId'),
    equalTo(`${canopusId}`)
  );
  const canopus = {
    name: '',
    custom: false,
  };

  try {
    const canopusSnap = await get(canopusQuery);
    canopusSnap.forEach((snap) => {
      // return the first:
      const canopusNameChild = snap.child('canopusName').val();
      const canopusCustomChild = snap.child('custom').val();
      logger.info(
        `Available Canopus Name: ${JSON.stringify(snap.val())}, /canopus key: ${
          snap.key
        }`
      );
      if (typeof canopusNameChild === 'string' && canopusNameChild !== '') {
        logger.info(
          `Setting Canopus Name: ${JSON.stringify(snap.val())}, /canopus key: ${
            snap.key
          }`
        );
        canopus['name'] = canopusNameChild;
        if (
          typeof canopusCustomChild !== 'undefined' &&
          canopusCustomChild === true
        ) {
          canopus['custom'] = true;
        }
      }
    });
  } catch (e) {
    logger.error('getCanopus: error calling firebase:', e);
  }

  return canopus;
};

export const D2cArtistMapping = () => {
  const damApp = firebaseService.getDAMApp();
  const partyApp = firebaseService.getPartyServiceApp();
  const partyDatabase = getDatabase(partyApp);
  const damAuth = getAuth(damApp);

  const TABLE_NAME = 'd2c_de_artists';

  const ability = useContext(AbilityContext);
  const isArtistsAdmin = ability.can('manage', 'artist_admin');

  const d2cArtistsRef = ref('artists');
  const d2cQuery = query(
    d2cArtistsRef,
    orderByChild('source'),
    equalTo(`${ARTIST_SOURCE}`)
  );
  const [d2cArtistsList, loading, error] = useObjectVal(d2cQuery as any);
  const [values, setValues] = useState([]);
  const [valuesLoading, setValuesLoading] = useState(false);

  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
  const uid = damAuth.currentUser.uid;
  const userColsRef = ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  // when d2cArtistsList changes
  // and there are no values yet
  // put values into the correct shape + lookup enumeration labels + update state with mapped values
  useEffect(() => {
    if (d2cArtistsList) {
      logger.info(
        `[D2cArtistMapping] d2cArtistsList: ${JSON.stringify(
          d2cArtistsList
        ).substring(0, 100)}`
      );
    }

    if (d2cArtistsList === null) {
      setValuesLoading(true);
      setValues([]);
    }

    if (values.length === 0 && d2cArtistsList) {
      const d2cArtists = Object.keys(d2cArtistsList).filter((k) => {
        return d2cArtistsList[k];
      });

      setValuesLoading(true);
      setValues(
        d2cArtists.map((k) => {
          const value = d2cArtistsList[k];
          const labels = { ...value };
          return { ...labels, key: k };
        })
      );
    }
  }, [d2cArtistsList]); // when d2cArtistsList

  useEffect(() => {
    setValuesLoading(false);
  }, [values]);

  const updateMyData = async (rowIndex, columnId, value, updateTableData) => {
    logger.debug(
      `rowIndex: ${rowIndex}, columnId: ${columnId}, value: ${value}`
    );
    const d2cArtistRef = ref(`artists/${values[rowIndex].key}`);
    const d2cArtist = {};
    const now = moment().unix();
    d2cArtist[columnId] = value;
    d2cArtist['modified'] = now;
    try {
      await update(d2cArtistRef, d2cArtist);
    } catch (e) {
      logger.error('[updateMyData] - error updating d2cArtist row: ', e);
    }

    if (updateTableData) {
      setValues((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
              ['modified']: now,
            };
          }
          return row;
        })
      );
    }
  };

  const EditableCRMStatus = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    cellInfo,
    updateMyData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    //const hasCanopusName =
    //  original.canopus_name !== '' && original.canopus_name !== undefined;
    const isDisabled = original.is_excluded === true;

    const handleClick = async (verify: boolean) => {
      setValue(verify);
      await updateMyData(index, 'crm_status', verify, true);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const partyId = original.party_id;

    if (value === 'true') {
      return (
        <React.Fragment>
          true
          {
            <Button
              className={artistMappingStyles.artistMappingVerify}
              onClick={() => {
                handleClick(false);
              }}
              disabled={isDisabled}
            >
              Set to false
            </Button>
          }
        </React.Fragment>
      );
    }

    const hasparty = !isNilOrEmpty(partyId);

    return (
      <React.Fragment>
        false
        {hasparty && (
          <Button
            className={artistMappingStyles.artistMappingVerify}
            onClick={() => {
              handleClick(true);
            }}
            disabled={isDisabled}
          >
            Set to true
          </Button>
        )}
      </React.Fragment>
    );
  };

  const partyIDAccessor = ({ row: { original, index } }) => {
    const [validPartyID, setValidPartyID] = useState(original.party_id && true);
    const [party_id, setPartyID] = React.useState(original.party_id);
    const [loadingPartyID, setLoadingPartyID] = useState(false);

    const partyIDInpRef = React.useRef<HTMLInputElement>(null);

    const clearPartyID = async () => {
      const changes = {};
      const now = moment().unix();

      changes[`${original.key}/party_id`] = null;
      changes[`${original.key}/party_name`] = null;
      changes[`${original.key}/rollup_name`] = null;
      changes[`${original.key}/rollup_id`] = null;
      changes[`${original.key}/canopus_id`] = null;
      changes[`${original.key}/canopus_name`] = null;
      changes[`${original.key}/modified`] = now;

      setValues((old) =>
        old.map((row, currentIndex) => {
          if (index === currentIndex) {
            return {
              ...old[index],
              party_id: null,
              party_name: null,
              rollup_name: null,
              rollup_id: null,
              canopus_id: null,
              canopus_name: null,
              modified: now,
            };
          }
          return row;
        })
      );

      setValidPartyID(false);
      setLoadingPartyID(false);
      update(d2cArtistsRef, changes);
      return;
    };
    const setNewPartyID = async () => {
      const changes = {};
      const now = moment().unix();

      const partyServiceRef = fbRef(partyDatabase, `party_id/${party_id}`);
      const artistPartyRef = await get(partyServiceRef);

      if (!artistPartyRef.exists()) {
        setValidPartyID(false);
        setLoadingPartyID(false);
        return;
      }

      const { party_name, rollup_id, rollup_name, canopus_id } =
        await artistPartyRef.val();
      const { name } = await getCanopus(canopus_id);

      changes[`${original.key}/party_id`] = partyIDInpRef.current.value;
      changes[`${original.key}/party_name`] = party_name || null;
      changes[`${original.key}/rollup_id`] = rollup_id || null;
      changes[`${original.key}/rollup_name`] = rollup_name || null;
      changes[`${original.key}/canopus_id`] = canopus_id || null;
      changes[`${original.key}/canopus_name`] = name;
      changes[`${original.key}/modified`] = now;

      setValues((old) =>
        old.map((row, currentIndex) => {
          if (index === currentIndex) {
            return {
              ...old[index],
              party_id: party_id,
              party_name: party_name || null,
              rollup_name: rollup_name || null,
              rollup_id: rollup_id || null,
              canopus_id: canopus_id || null,
              canopus_name: name,
              modified: now,
            };
          }
          return row;
        })
      );

      update(d2cArtistsRef, changes);
      setValidPartyID(true);
      setLoadingPartyID(false);
    };

    useEffect(() => {
      if (loadingPartyID) {
        setNewPartyID();
      }
    }, [loadingPartyID]);

    return (
      <div style={{ display: 'flex', gap: 20 }}>
        <Input
          innerRef={partyIDInpRef}
          placeholder="No party id set"
          onChange={(e) => {
            e.target.value = e.target.value.replace(/\D/g, '');
            if (e.target.value !== party_id) {
              if (!e.target.value) {
                clearPartyID();
                return;
              }
              setPartyID(e.target.value);
              setLoadingPartyID(true);
            }
          }}
          defaultValue={party_id}
        />
        {loadingPartyID ? (
          <img
            src="/images/loading.gif"
            className={dotdigitalStyles.canopusLoader}
          />
        ) : validPartyID ? (
          <TickIcon fill="black" class={iconStyles.tick} />
        ) : (
          <CrossIcon fill="black" class={iconStyles.cross} />
        )}
      </div>
    );
  };
  const EditableExclude = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    cellInfo,
    updateMyData,
  }) => {
    const [value, setValue] = React.useState(initialValue);

    const handleClick = async (exclude: boolean) => {
      setValue(exclude);
      await updateMyData(index, 'is_excluded', exclude, true);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (value === 'true') {
      return (
        <React.Fragment>
          true
          {
            <Button
              className={artistMappingStyles.artistMappingVerify}
              onClick={() => {
                handleClick(false);
              }}
            >
              Set to false
            </Button>
          }
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        false
        {
          <Button
            className={artistMappingStyles.artistMappingVerify}
            onClick={() => {
              handleClick(true);
            }}
          >
            Set to true
          </Button>
        }
      </React.Fragment>
    );
  };

  const columns = React.useMemo(() => {
    let cols: any = [
      {
        Header: 'Key',
        accessor: 'key',
        id: 'key',
      },
      {
        Header: 'Source',
        accessor: 'source',
        id: 'source',
      },
      {
        Header: 'Partner Artist Name',
        accessor: 'partner_artist_name',
        id: 'partner_artist_name',
      },
      {
        Header: 'Party Name',
        accessor: 'party_name',
        id: 'party_name',
      },
      {
        Header: 'Party ID',
        accessor: 'party_id',
        id: 'party_id',
        Cell: partyIDAccessor,
      },
      {
        Header: 'Rollup name',
        accessor: 'rollup_name',
        id: 'rollup_name',
      },
      {
        Header: 'Rollup ID',
        accessor: 'rollup_id',
        id: 'rollup_id',
      },
      {
        Header: 'Canopus ID',
        accessor: 'canopus_id',
        id: 'canopus_id',
      },
      {
        Header: 'Canopus Name',
        accessor: 'canopus_name',
        id: 'canopus_name',
      },
      {
        Header: 'Custom Canopus',
        accessor: (row) => {
          if (row.custom_canopus === true) {
            return 'true';
          }

          return 'false';
        },
        id: 'custom_canopus',
      },
      {
        Header: 'CRM Status',
        accessor: (row) => {
          if (row.crm_status === true) {
            return 'true';
          }

          return 'false';
        },
        Cell: EditableCRMStatus,
        id: 'crm_status',
      },
      {
        Header: 'Created',
        accessor: (row) => {
          if (row.created) {
            return moment
              .unix(parseInt(row.created))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'created',
      },
      {
        Header: 'Modified',
        accessor: (row) => {
          if (row.modified) {
            return moment
              .unix(parseInt(row.modified))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'modified',
      },
      {
        Header: 'Removed from BQ',
        accessor: (row) => {
          if (row.removed_from_bq === true) {
            return 'true';
          }

          return 'false';
        },
        id: 'removed_from_bq',
      },
    ];

    const excludeCol = {
      Header: 'Exclude',
      accessor: (row) => {
        if (row.is_excluded === true) {
          return 'true';
        }

        return 'false';
      },
      Cell: EditableExclude,
      id: 'is_excluded',
    };

    if (isArtistsAdmin === true) {
      cols = [...cols, excludeCol];
    }

    return cols;
  }, [isArtistsAdmin]);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize values - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => values, [values]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const tableData = useTable(
    {
      columns,
      data: records,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'artist_name',
            desc: false,
          },
        ],
        pageSize: 20,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
      updateMyData,
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  React.useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  if (loading || valuesLoading || userColsLoading) {
    return <Loader loading={true} />;
  }

  if (error) {
    return <div>{`Error: ${error.message}`}</div>;
  }

  if (userColsError) {
    return <div>Error loading cols</div>;
  }

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);

  return (
    <div className={pagesStyles.fullHeight}>
      {values && (
        <div className={`${pagesStyles.noPadding} ${pagesStyles.fullHeight}`}>
          <Row className={pagesStyles.headerRow}>
            <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
              <Button onClick={toggleColumnsSelect}>Columns</Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Collapse isOpen={isColumnsSelectOpen}>
                <ColumnsSelector
                  columns={columns}
                  userCols={userCols}
                  tableName={TABLE_NAME}
                />
              </Collapse>
            </Col>
          </Row>
          <Row className={pagesStyles.fullHeight}>
            <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
              <Can I="manage" a="d2c_artist_mapping">
                <D2cArtistMappingTable {...tableData} />
              </Can>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
